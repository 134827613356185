import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import localeResources from "../../Locales/localResources.json";
import { getTargetModelLanguageResource } from "../../Utils/CommonUtils";
import Emitter from "../../Utils/Emitter";
import GlobalConfig from "@src/GlobalConfig";
import { IKVProps } from "@src/Model/IKVProps";
import LanguageLocalForageStore from "@src/Localforage/LanguageLocalForageStore";
import _ from "lodash";
import { LANG_LIST, languageValueToRealLangMap } from './const'

const getSupportLangs = (returnCode = false) => {
  const lngList = localStorage.getItem("languageList") || "";
  let list: IKVProps[] = LANG_LIST;

  try {
    const _list = JSON.parse(lngList);
    if (_list && Array.isArray(_list) && _list.length > 0) {
      list = _list;
      // 添加兜底语言（如果不存在）
      if (!list.some((i) => i.value === "en_us")) {
        list.push({
          label: "United States(English)",
          value: "en_us",
        });
      }
    }
  } catch (err) {
    console.error('Error parsing language list:', err);
    list = [...list];
  }

  // 确保返回值的一致性
  if (returnCode) {
    return list.map((i) => i.value);
  }

  console.log('list', list);
  return list;
};

const getValue = (loading = false) => {
  const parseResource = (resource: any) => {
    try {
      const getTargetLanguageResource = (languageKey: string) => {
        return {
          Common: getTargetModelLanguageResource(resource, languageKey),
          InternationalWebsite: getTargetModelLanguageResource(resource, languageKey),
        };
      };

      let langObj = {};
      // 加一个保底
      const list = getSupportLangs();
      if (list && list.length > 0) {
        list.map((item: any) => {
          langObj = {
            ...langObj,
            // 自定义语言value    // languageValueToRealLangMap[item.value] - 实际后台对应的语言
            [item.value]: getTargetLanguageResource(languageValueToRealLangMap[item.value]),
          };
        });
      }
      return langObj;
    } catch (error) {
      return localeResources || {};
    }
  };
  return new Promise((resolve) => {
    if (loading) {
      Emitter.emit("languageLoad", true);
    }
    LanguageLocalForageStore.get("language")
      .then((res: IKVProps[]) => {
        resolve(parseResource(res));
      })
      .catch((error) => {
        console.error("i18n-not memory error:", error);
        resolve(parseResource(localeResources));
      });
  });
};

const i18nOptions: IKVProps = {
  // debug: process.env.NODE_ENV === "development",
  debug: false,
  fallbackLng: GlobalConfig.isInternational ? "es_es" : "zh_cn",
  resources: localeResources,
  defaultNS: "Common",
  interpolation: {
    escapeValue: false,
  },
  supportedLngs: [...getSupportLangs(true)],
  load: "currentOnly",
  returnEmptyString: false,
  detection: {
    order: ['path', 'htmlTag', 'cookie', 'localStorage', 'navigator'],
    convertDetectedLanguage: (lng: string) => lng.replace("-", "_"),
  },
  languageLabel: getSupportLangs().reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {} as Record<string, string>),
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nOptions);


// 添加获取当前语言标签的方法 for getCurrentLanguageLabel
i18n.languageLabel = i18nOptions.languageLabel;

// 获取当前语言label for nav switchLanguage
i18n.getCurrentLanguageLabel = () => {
  const currentLng = i18n.language;
  return i18n.languageLabel[currentLng] || undefined;
};

// 获取语言value for switch CN <=> International
i18n.getSupportedLngs = () => {
  console.log('getSupportLangs', getSupportLangs(true));
  return _.map(_.filter(getSupportLangs(), (o) => !o.isForeign), "value")
};

export const i18nAddResource = (loading = false, cb?: () => void) => {
  getValue(loading).then((resources: any) => {
    console.log('resources', _.cloneDeep(resources));
    Object.keys(resources).forEach((key: string) => {
      for (const key2 in resources[key]) {
        i18n.addResourceBundle(key, key2, resources[key][key2], true, true);
      }
    });
    Emitter.emit("languageLoad", false);
    i18n.use(LanguageDetector).use(initReactI18next).init({
      ...i18nOptions,
      resources,
    });
    cb?.();
  });
};

export const i18nReInit = (supportedLngs: string[]) => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({ ...i18nOptions, supportedLngs })
    .then(() => {
      i18nAddResource();
    });
};

export default i18n;