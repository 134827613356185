import Experience from "./Experience";
import FooterRight from "./Right/index.tsx";
import FooterBottom from "./Bottom/index.tsx";
import "./index.less";

const PCFooter = () => {
  return (
    <div className="PCFooterWrap">
      <div className="flex footerContent">
        <Experience />
        <div className="right flex-1">
          <FooterRight />
        </div>
      </div>
      <div className="divider"></div>
      <FooterBottom />
    </div>
  );
};
export default PCFooter;
