enum DownLoadCenterEnum {
    downLoadCenterBannerHeaderTitleItalicBeforeSpan = "downLoadCenter.bannerHeaderTitleItalicBeforeSpan",
    downLoadCenterTitleItalic = "downLoadCenter.bannerHeaderTitleItalic",
    downLoadCenterBannerHeaderDesc1 = "downLoadCenter.bannerHeaderDesc1",
    downLoadCenterBannerHeaderDesc2 = "downLoadCenter.bannerHeaderDesc2",
    downLoadCenterBannerHeaderDesc3 = "downLoadCenter.bannerHeaderDesc3",

    downLoadCenterSelectRegion = "downLoadCenter.selectRegion",
    downLoadCenterSelectRegionOpt1 = "downLoadCenter.selectRegionOpt1",
    downLoadCenterSelectRegionOpt2 = "downLoadCenter.selectRegionOpt2",
    downLoadCenterSelectRegionOpt3 = "downLoadCenter.selectRegionOpt3",
    downLoadCenterSelectProduct = "downLoadCenter.selectProduct",
    downLoadCenterSelectProductOpt1 = "downLoadCenter.selectProductOpt1",
    downLoadCenterSelectProductOpt2 = "downLoadCenter.selectProductOpt2",
    downLoadCenterSelectProductOpt3 = "downLoadCenter.selectProductOpt3",
    downLoadCenterSelectProductOpt4 = "downLoadCenter.selectProductOpt4",

    downLoadCenterSelectVersion = "downLoadCenter.selectVersion",
    downLoadCenterOperatingSystem = "downLoadCenter.operatingSystem",
    downLoadCenterText = "downLoadCenter.downLoadText",

    downloadCenterLowerConfigInfo = "downLoadCenter.windowsLowerConfigInfo",
    downLoadCenterSystem = "downLoadCenter.windowsSystem",
    downLoadCenterCpu = "downLoadCenter.windowsCpu",
    downLoadCenterMemory = "downLoadCenter.windowsMemory",
    downLoadCenterWindowsStorage = "downLoadCenter.windowsStorage",
    downLoadCenterRecommendConfig = "downLoadCenter.recommendConfig",

    downLoadCenterWindowsRecommendCpu = "downLoadCenter.windowsRecommendCpu",
    downLoadCenterWindowsRecommendMemory = "downLoadCenter.windowsRecommendMemory",
    downLoadCenterWindowsRecommendStorage = "downLoadCenter.windowsRecommendStorage",


    downLoadCenterAndroidLowerCpu = "downLoadCenter.androidLowerCpu",
    downLoadCenterAndroidRecommendCpu = "downLoadCenter.androidRecommendCpu",
    downLoadCenterAndroidRecommendMemory = "downLoadCenter.androidRecommendMemory",

    downLoadCenterBackGroundUrlTitle = "downLoadCenter.backGroundUrlTitle",
    downLoadCenterBackGroundUrlDesc = "downLoadCenter.backGroundUrlDesc",
    downLoadCenterBackGroundUrlContactNowBtnText = "downLoadCenter.backGroundUrlContactNowBtnText"

}

export default DownLoadCenterEnum