enum HomeEnum {
    // nav
    // 产品
    products = "international.products",
    // 休息
    restoPara = "international.restoPara",
    // 效率
    efficiency = "international.efficiency",
    // 联系
    contact = "international.contact",
    // 关于我们
    about = "international.about",
    // bo
    boLinkBtn = "international.boLinkBtn",
    // 申请试用
    applyLinkBtn = "international.applyLinkBtn",
    // 为您的客户
    forYourClients = "international.forYourClients",
    // 用于订购的QR
    QROrder = "international.QROrder",
    // 储量
    reserves = "international.reserves",
    // 数字菜单
    digitalMenu = "international.digitalMenu",
    // 报摊
    quioscos = "international.quioscos",
    // 一体化管理
    integration = "international.integration",
    // 后台
    backoffice = "international.backoffice",
    // TPV酒店
    TPVHotel = "international.TPVHospitality",
    // KDS
    KDS = "international.KDS",
    // RSinright应用程序
    RSinjightApplication = "international.RSinjightApplication",
    // 送货和外卖
    deliveryAndTakeout = "international.deliveryAndTakeout",
    // 管理
    administrate = "international.administrate",
    // 会员
    member = "international.member",
    // 营销
    marketing = "international.marketing",
    // 硬件
    hardware = "international.hardware",
    // 连锁管控
    gestionyControl = "international.gestionyControl",
    // 下载中心
    downloadCenter = "international.downloadCenter",
    // 电子菜单
    electricMenu = "international.electricMenu",
    // 
    descargar = "international.descargar",
    recursos = "international.recursos",
    blog = "international.blog",
    centro = "international.centro",
    // RestoSuite 为酒店业提供的数字解决方案
    introCompanyBusinessH1Span1 = "international.introCompanyBusinessH1Span1",
    introCompanyBusinessH1Span2 = "international.introCompanyBusinessH1Span2",
    introCompanyBusinessH1Span3 = "international.introCompanyBusinessH1Span3",
    // RestoSuite 是最佳的酒店和餐厅软件解决方案，适用于重视服务质量并寻求创新的企业。立即让你的业务融入数字世界！
    introCompanyIntro1 = "international.introCompanyIntro1",
    introCompanyIntro2 = "international.introCompanyIntro2",
    introCompanyCarsouelCardTitle1 = "international.introCompanyCarsouelCardTitle1",
    introCompanyCarsouelCardTitle2 = "international.introCompanyCarsouelCardTitle2",
    introCompanyCarsouelCardDsc1 = "international.introCompanyCarsouelCardDsc1",
    introCompanyCarsouelCardDesc2 = "international.introCompanyCarsouelCardDesc2",
    introCompanyCarsouelCardDesc3 = "international.introCompanyCarsouelCardDesc3",
    introCompanyCarsouelCardInput = "international.introCompanyCarsouelCardInput",
    // 已经信任我们的客户
    brandTitle = "international.brandTitle",
    // 为什么我们获得客户信任
    whyTrustTitle = "international.whyTrustTitle",
    // 我们专注于服务整个欧洲、美洲和亚洲。
    whyTrustReason = "international.whyTrustReason",
    // 平均成本节约
    whyTrustAverageCostSavings = "international.whyTrustAverageCostSavings",
    // 平均销售额增长
    whyTrustAverageSalesGrowth = "international.whyTrustAverageSalesGrowth",
    // 低于平均误差
    whyTrustBelowAverageError = "international.whyTrustBelowAverageError",
    // 我们简化了你的日常生活
    productsTitle = "international.productsTitle",
    // 数字菜单
    productsQRDigital = "international.productsDigital",
    // 自建亭
    productsNewstand = "international.productsNewstand",
    // 你的担忧是我们的；我们团队开发的SaaS系统为您的日常生活提供解决方案
    advantagedesc1 = "international.advantagedesc1",
    // 并不是我们所有的客户都是一样的，解决方案永远不能是通用的，在Restosuite，我们开发了满足您需求的管理系统。
    advantagedesc2 = "international.advantagedesc2",
    // 因为我们有相同的目标，成长，在Restosuite，我们提供多种解决方案，让您的客户像在家一样。
    advantagedesc3 = "international.advantagedesc3",
    // 后台 - Resto
    sceneBackGroundTitleH1 = "international.sceneBackGroundTitleH1",

    // 易于管理！
    sceneBackGroundP1 = "international.sceneBackGroundP1",
    // 在这种管理环境中，您可以随时修改菜单和促销活动，并根据餐厅的需求进行个性化。还有更多。。。
    sceneBackGroundP2 = "international.sceneBackGroundP2",
    // 要求您的演示
    requestYourPresentationButton = "international.requestYourPresentationButton",
    // RSinright应用程序
    sceneRSinjightApplicationTitle = "international.sceneRSinjightApplicationTitle",
    // 保持你的账目清晰，并进行准确的财务控制。准确监测收入、支出和利润率，确保健全和透明的财务管理。
    sceneRSinjightApplicationP1 = "international.sceneRSinjightApplicationP1",
    // 它只使用一种工具来管理您的业务。
    sceneTPVHotelP1 = "international.sceneTPVHotelP1",
    // 通过我们的专业计划简化您餐厅的管理。我们的餐厅后台为您提供从库存到工资单的全面运营控制，以确保您的业务效率和成功。
    sceneTPVHotelP2 = "international.sceneTPVHotelP2",
    // 常见问题解答
    questionTitle = "international.questionTitle",
    // 在雇用Restosuite时是否有任何永久承诺？
    question1 = "international.question1",
    // 不，目前我们不需要任何形式的永久性来使用我们的系统。
    question1Answer1 = "international.question1Answer1",
    // 客户是否需要下载应用程序才能下单？
    question2 = "international.question2",
    // figma没写
    question2Answer2 = "international.question1Answer2",
    // 你有任何疑问吗？
    contactTitle = "international.contactTitle",
    // 你不知道如何开始？联系我们，我们将尽快与您联系，以解决任何问题。
    contactAnswer = "international.contactAnswer",
    // 现在免费试用！
    contactButton = "international.contactButton",
    footerColumn1IntroTitle = "international.footerColumn1IntroTitle",
    footerColumn1IntroDesc1 = "international.footerColumn1IntroDesc1",
    footerColumn1IntroDesc2 = "international.footerColumn1IntroDesc2",
    footerColumn1PhoneTtile = "international.footerColumn1PhoneTtile",
    footerColumn1Phone1 = "international.footerColumn1Phone1",
    footerColumn1Phone2 = "international.footerColumn1Phone2",
    footerColumn1Phone3 = "international.footerColumn1Phone3",
    footerColumn1Email = "international.footerColumn1Email",
    footerColumn1CopyRight = "international.footerColumn1CopyRight",
    footerColumn1AllRightsReserved = "international.footerColumn1AllRightsReserved",
    // 
    footerColumnsTitle = "international.footerColumnsTitle",
    footerPrivacyPolicy = "international.footerPrivacyPolicy",
    footerlegalNotice = "international.footerlegalNotice",
    cookiePolicy = "international.cookiePolicy",

    userAgreement = "international.userAgreement",
    privacyPolicyMultipleSets = "international.privacyPolicyMultipleSets",
    legalDisclaimer = "international.legalDisclaimer",
    changeCookiePreferences = "international.changeCookiePreferences",

    // cookie
    cookieTips1 = "home.cookie1",
    cookieTips1SpanLink = "home.cookie1SpanLink",
    cookieTips2 = "home.cookie2",
    cookieBtnAccept = "home.cookieBtnAccept",
    cookieBtnReject = "home.cookieBtnReject",

    // All rights reserved
    // All rights reserved
    // 保留所有权利
    footerGlobalService = "footer.globalService",


}

export default HomeEnum