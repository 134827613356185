import { useState, useContext, useEffect, useMemo } from "react";
import { ReactComponent as Internet } from "@src/assets/international/svgs/internet.svg";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "@src/Basic/Language/Provider.tsx";
import { useStore } from "@src/Store";
import GlobalConfig from "@src/GlobalConfig";
import styles from "./index.module.less";
import { IKVProps } from "@src/Model/IKVProps";
import _ from "lodash";
import { useMatch } from "react-router-dom";

const SwitchLanguage = () => {
  const { setLanguage, language } = useContext(LanguageContext);

  const { commonStore } = useStore();
  const { t, i18n } = useTranslation();

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [languageDataSource, setLanguageDataSource] = useState<any[]>([]);
  // const [languageLabel, setLanguageLabel] = useState("");

  useEffect(() => {
    try {
      const allSupportLanguages = localStorage.getItem("languageList");
      const parseAllSupportLanguages = JSON.parse(allSupportLanguages ?? "[]");

      if (allSupportLanguages) {
        setLanguageDataSource(parseAllSupportLanguages);
      }
    } catch (error) {
      //
    }
  }, [i18n.language]);

  const switchLanguage = (targetLang: IKVProps) => {
    setLanguage(targetLang.value);
    // setLanguageLabel(targetLang.label);
    commonStore.setLanguage(targetLang.value);
    setIsHovered(false);
  };

  const handleOnChange = (item: IKVProps) => {
    switchLanguage(item);
    if (item.isForeign) {
      GlobalConfig.pushToCN();
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const languageLabel = useMemo(() => {
    console.log("switch languae", i18n.language, languageDataSource);
    const _languageLabel = _.find(languageDataSource, (o) => o.value === i18n.language)?.label;
    return i18n.getCurrentLanguageLabel() || _languageLabel;
  }, [i18n.language]);

  return (
    <div
      className="flex items-center relative cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Internet />
      <span className={`${styles.PCLanguageLabel} text-[#696974]`}>{languageLabel}</span>
      {isHovered && (
        <div className={`absolute ${styles.PCSwitchLanguage}`}>
          {languageDataSource.map((item) => {
            return (
              <div
                className={`${styles.PCLanguageItem}`}
                key={item.key}
                onClick={() => {
                  handleOnChange(item);
                }}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SwitchLanguage;
