enum KdsEnum {
    kdsBannerHeaderTitleP = "kds.bannerHeaderTitleP",
    kdsBannerHeaderDesc = "kds.bannerHeaderDesc",

    kdsHalfContainer1TextTitle = "kds.halfContainer1TextTitle",
    kdsHalfContainer1TextDesc = "kds.halfContainer1TextDesc",

    // 
    kdsPicTextDescContainer1Ttitle1 = "kds.picTextDescContainer1Ttitle1",
    kdsPicTextDescContainer1Ttitle2 = "kds.picTextDescContainer1Ttitle2",
    kdsPicTextDescContainer1Ttitle3 = "kds.picTextDescContainer1Ttitle3",

    // 
    kdsHalfContainer2TextTitle = "kds.halfContainer2TextTitle",
    kdsHalfContainer2TextDesc = "kds.halfContainer2TextDesc",

    // 
    kdsHalfContainer1TextIconListTitle = "kds.halfContainer1TextIconListTitle",
    kdsHalfContainer1TextIconListItemTitle1 = "kds.halfContainer1TextIconListItemTitle1",
    kdsHalfContainer1TextIconListItemDesc1 = "kds.halfContainer1TextIconListItemDesc1",

    kdsHalfContainer1TextIconListItemTitle2 = "kds.halfContainer1TextIconListItemTitle2",
    kdsHalfContainer1TextIconListItemDesc2 = "kds.halfContainer1TextIconListItemDesc2",

    kdsHalfContainer1TextIconListItemTitle3 = "kds.halfContainer1TextIconListItemTitle3",
    kdsHalfContainer1TextIconListItemDesc3 = "kds.halfContainer1TextIconListItemDesc3",

    kdsHalfContainer1TextIconListItemTitle4 = "kds.halfContainer1TextIconListItemTitle4",
    kdsHalfContainer1TextIconListItemDesc4 = "kds.halfContainer1TextIconListItemDesc4",

    // 
    kdsPicTextDescContainer2TopTtitle = "kds.picTextDescContainer2TopTtitle",
    kdsProductsCardTitle1 = "kds.productsCardTitle1",
    kdsProductsCardDesc1 = "kds.productsCardDesc1",

    kdsProductsCardTitle2 = "kds.productsCardTitle2",
    kdsProductsCardDesc2 = "kds.productsCardDesc2",

    kdsProductsCardTitle3 = "kds.productsCardTitle3",
    kdsProductsCardDesc3 = "kds.productsCardDesc3",



    // 
    kdsBackGroundUrlText2Title = "kds.backGroundUrlText2Title",

    // 



}

export default KdsEnum