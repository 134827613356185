/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactNode, useState, createContext, useEffect } from "react";
import i18n from "./i18n";
import { rootStore } from "@src/Store/index";

interface ILanguageContext {
  language: string;
  setLanguage: (lang: string) => void;
}

interface Props {
  children: ReactNode;
}

const defaultLang = i18n.language || rootStore.commonStore.language;
export const LanguageContext = createContext<ILanguageContext>({
  language: defaultLang,
  setLanguage: () => {},
});

export const LanguageContextProvider = ({ children }: Props) => {
  const pathLang = window.location.pathname.split("/")[1];
  const [language, setLanguage] = useState(pathLang || defaultLang);
  const setLanguageFunc = (str: string) => {
    i18n.changeLanguage(str).catch((error: Error) => {
      console.log("语言包加载错误：", error);
    });
    console.log("str========>", str);
    setLanguage(str);
  };
  useEffect(() => {
    setLanguageFunc(language);
  }, [language]);

  const languageValue = { language, setLanguage: setLanguageFunc };
  return <LanguageContext.Provider value={languageValue}>{children}</LanguageContext.Provider>;
};
