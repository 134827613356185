import { ReactNode } from "react";

type IProps = {
  children?: ReactNode;
};
const NotFound = (props: IProps) => {
  const { children } = props;
  return (
    <div className="flex w-full h-full items-center justify-center">
      {
        <div className="404 text-6xl font-mono font-extralight underline underline-offset-8">
          {children || "404"}
        </div>
      }
    </div>
  );
};

export default NotFound;
