enum MemberEnum {
    memberBannerHeaderTitleItalic = "member.bannerHeaderTitleItalic",
    memberBannerHeaderTitleSpan = "member.bannerHeaderTitleSpan",
    memberBannerHeaderDesc = "member.bannerHeaderDesc",

    memberHalfContainer1TextTitle = "member.halfContainer1TextTitle",
    memberHalfContainer1TextDesc = "member.halfContainer1TextDesc",

    memberHalfContainer2TextTitle = "member.halfContainer2TextTitle",
    memberHalfContainer2TextDesc = "member.halfContainer2TextDesc",

    // 

    memberHalfContainer3TextTitle1 = "member.halfContainer3TextTitle1",
    memberHalfContainer3TextDesc1 = "member.halfContainer3TextDesc1",

    memberHalfContainer3TextTitle2 = "member.halfContainer3TextTitle2",
    memberHalfContainer3TextDesc2 = "member.halfContainer3TextDesc2",

    memberHalfContainer3TextTitle3 = "member.halfContainer3TextTitle3",
    memberHalfContainer3TextDesc3 = "member.halfContainer3TextDesc3",

    memberHalfContainer3TextTitle4 = "member.halfContainer3TextTitle4",
    memberHalfContainer3TextDesc4 = "member.halfContainer3TextDesc4",

    // 
    memberBackGroundUrlTextTitle = "member.backGroundUrlTextTitle",

}


export default MemberEnum