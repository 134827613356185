import { useTranslation } from "react-i18next";
import "./loading.css";
import { PropsWithChildren, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Loading = () => {
  return (
    <div className="flex h-full w-full items-center justify-center flex-col">
      <LoadingOutlined
        style={{
          color: "rgb(85, 85, 85)",
          fontSize: "36px",
        }}
      />
      <div className="loadingText my-2 text-blue-400 text-[14px]">loading</div>
    </div>
  );
};

interface Prop {
  className?: string;
  loading?: boolean;
}

export const LoadingWrapper = (prop: PropsWithChildren<Prop>) => {
  const [loading, setLoading] = useState(prop.loading || false);

  useEffect(() => {
    if (typeof prop.loading === "boolean" && prop.loading !== loading) {
      setLoading(prop.loading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop.loading]);

  return (
    <div className={`flex flex-col w-full h-full overflow-hidden relative ${prop.className}`}>
      {loading ? (
        <div className="absolute left-0 top-0 w-full h-full z-10 bg-white/70 transition-all">
          <div className="flex h-full w-full items-center justify-center flex-col">
            <Loading></Loading>
          </div>
        </div>
      ) : (
        <></>
      )}
      {prop.children}
    </div>
  );
};

export default Loading;
