import { makeAutoObservable } from 'mobx'
import CommonStore from './CommonStore'
import { createContext, useContext } from 'react'

// 全局可观察状态
export class RootStore {
  commonStore: CommonStore

  constructor() {
    makeAutoObservable(this)

    this.commonStore = new CommonStore()
  }
}

const rootStore = new RootStore()
const _context = createContext(rootStore)
const useStore = () => useContext(_context)
export { useStore, rootStore }
