
// 业务页面路由配置
import { lazy } from "react";
import { IRoute } from "@src/Model/IRoute";

const IndustryRoutes: IRoute[] = [
    {
        path: "/:lang/industry/fast-food",
        Component: lazy(() => import("@src/Pages/CN/PC/Industry/FastFood")),
    },
    {
        path: "/:lang/industry/dinner",
        Component: lazy(() => import("@src/Pages/CN/PC/Industry/Dinner")),
    },
    {
        path: "/:lang/industry/tea-drinks",
        Component: lazy(() => import("@src/Pages/CN/PC/Industry/TeaDrinks")),
    },
];


export default IndustryRoutes;
