enum OnlineRestaurantEnum {
    storeClosedLoop = "cn.storeClosedLoop",
    multiPlatform = "cn.multiPlatform",
    multiMode = "cn.multiMode",
    multiStyle = "cn.multiStyle",
    onlineOrderClosedLoop = "cn.onlineOrderClosedLoop",
    supportDownCode = "cn.supportDownCode",
    supportConfigPathAndLogo = "cn.supportConfigPathAndLogo",
    unifyReceiveOrder = "cn.unifyReceiveOrder",
    varyCountryAndRegionReceiveOrder = "cn.varyCountryAndRegionReceiveOrder",
    intelligentDistributeOrder = "cn.intelligentDistributeOrder",
    customizedUnifiedDistribution = "cn.customizedUnifiedDistribution",
    productSync = "cn.productSync",
    fastMap = "cn.fastMap",
    consolidatedReturn = "cn.consolidatedReturn",
    centralizedStatistics = "cn.centralizedStatistics",
    payment = "cn.payment",
    manyPaymentMethods = "cn.manyPaymentMethods",
    integratedPayment = "cn.integratedPayment",
    abutmentPayment = "cn.abutmentPayment",
    onlineAndOfflineInvoice = "cn.onlineAndOfflineInvoice",
    supportElecPaperInvoice = "cn.supportElecPaperInvoice",
    goodsAlcoholTaxConfig = "cn.goodsAlcoholTaxConfig",
    compliantDiffTaxPolicies = "cn.compliantDiffTaxPolicies",
    supportDiffTaxControlSystem = "cn.supportDiffTaxControlSystem",
    reservation = "cn.reservation",
    bookRefundDeposit = "cn.bookRefundDeposit",
    displayPreOrdersByDate = "cn.displayPreOrdersByDate",
    supportBookByTable = "cn.supportBookByTable",
    supportMobileOnlineBook = "cn.supportMobileOnlineBook",
    queueDiffChannels = "cn.queueDiffChannels",
    messageOutreach = "cn.messageOutreach",
    callDeviceConnect = "cn.callDeviceConnect",
    onlineOrderReview = "cn.onlineOrderReview",
    giftedActivities = "cn.giftedActivities",
    analysisImprove = "cn.analysisImprove",
    merchantPlatformCoupon = "cn.merchant&PlatformCoupon",
    abutmentCoupon = "cn.abutmentCoupon",
    supportOnlineOfflineVerification = "cn.supportOnlineOfflineVerification",
    supportGiftCoupons = "cn.supportGiftCoupons",
}
export default OnlineRestaurantEnum;