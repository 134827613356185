enum MarketingEnum {
    marketingBannerHeaderTitleSpan1 = "marketing.bannerHeaderTitleSpan1",
    marketingBannerHeaderTitleItalic = "marketing.bannerHeaderTitleItalic",
    marketingBannerHeaderTitleSpan2 = "marketing.bannerHeaderTitleSpan2",
    marketingBannerHeaderDesc = "marketing.bannerHeaderDesc",

    marketingHalfContainer1TextTitle = "marketing.halfContainer1TextTitle",
    marketingHalfContainer1TextDesc = "marketing.halfContainer1TextDesc",

    marketingBackGroundUrlTextTitle = "marketing.backGroundUrlTextTitle",

    marketingHalfContainer2TextTitle = "marketing.halfContainer2TextTitle",
    marketingHalfContainer2TextDesc = "marketing.halfContainer2TextDesc",

    // 



}

export default MarketingEnum