export const LANG_LIST = [
    {
        label: "Australia(English)",
        value: "en_au",
    },
    {
        label: "Canada(English)",
        value: "en_ca",
    },
    // {
    //   label: "Deutschland(Deutsch)",
    //   value: "de_de",
    //   isForeign: true,
    // },
    {
        label: "Deutschland(English)",
        value: "en_de",
    },
    {
        label: "España(Español)",
        value: "es_es",
    },
    // {
    //   label: "Italia(Italiano)",
    //   value: "it_it",
    //   isForeign: true,
    // },
    {
        label: "Singapore(English)",
        value: "en_sg",
    },
    {
        label: "United Kingdom(English)",
        value: "en_gb",
    },
    {
        label: "United States(English)",
        value: "en_us",
    },
    {
        label: "中国(简体中文)",
        value: "zh_cn",
    },
    {
        label: "台湾(中国)(繁體中文)",
        value: "zh_tw",
    },
]

export const languageValueToRealLangMap: Record<string, string> = {
    en_au: "en_US",
    en_ca: "en_US",
    de_de: "de_DE",
    en_de: "en_US",
    es_es: "es",
    it_it: "it_IT",
    en_sg: "en_US",
    en_gb: "en_US",
    en_us: "en_US",
    zh_cn: "zh_CN",
    zh_tw: "zh_HK"
};