enum RestoParaEnum {
    restoParaIntrosRow1 = "restoPara.restoParaIntrosRow1",

    restoParaCateGoryName1 = "restoPara.CateGoryName1",
    restoParaCateGoryName2 = "restoPara.CateGoryName2",
    restoParaCateGoryName3 = "restoPara.CateGoryName3",
    restoParaCateGoryName4 = "restoPara.CateGoryName4",
    restoParaCateGoryName5 = "restoPara.CateGoryName5",
    restoParaCateGoryName6 = "restoPara.CateGoryName6",
    restoParaCateGoryName7 = "restoPara.CateGoryName7",
    restoParaCateGoryName8 = "restoPara.CateGoryName8",
    restoParaCateGoryName9 = "restoPara.CateGoryName9",

    restoParaIntrosRow2 = "restoPara.restoParaIntrosRow2",



}

export default RestoParaEnum