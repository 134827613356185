enum QuioscosEnum {
    quioscosBannerHeaderTitleItalic = "quioscos.bannerHeaderTitleItalic",
    quioscosBannerHeaderTitleSpan1 = "quioscos.bannerHeaderTitleSpan1",
    quioscosBannerHeaderTitleSpan2 = "quioscos.bannerHeaderTitleSpan2",
    quioscosBannerHeaderDesc = "qesquioscos.bannerHeaderDesc",

    // 
    quioscosHalfContainer1TextDesc = "quioscos.halfContainer1TextDesc",
    quioscosHalfContainer1TextItalicBeforeSpan1 = "quioscos.halfContainer1TextItalicBeforeSpan1",
    quioscosHalfContainer1TextItalicSpan1 = "quioscos.halfContainer1TextItalicSpan1",
    quioscosHalfContainer1TextItalicAfterSpan1 = "quioscos.halfContainer1TextItalicAfterSpan1",
    quioscosHalfContainer1TextItalicSpan2 = "quioscos.halfContainer1TextItalicSpan2",
    quioscosHalfContainer1TextItalicAfterSpan2 = "quioscos.halfContainer1TextItalicAfterSpan2",

    // 
    quioscosDescIntro1 = "quioscos.descIntro1",
    quioscosDescIntro2SPan1 = "quioscos.descIntro2SPan1",
    quioscosDescIntro2SPan2 = "quioscos.descIntro2SPan2",
    quioscosDescIntro2SPan3 = "quioscos.descIntro2SPan3",

    // 
    quioscosPicDescConatiner1Title1 = "quioscos.picDescConatiner1Title1",
    quioscosPicDescConatiner1Title2 = "quioscos.picDescConatiner1Title2",
    quioscosPicDescConatiner1Title3 = "quioscos.picDescConatiner1Title3",

    // 
    quioscosHalfContainer2TextTitle = "quioscos.halfContainer2TextTitle",
    quioscosHalfContainer2TextDesc = "quioscos.halfContainer2TextDesc",

    quioscosHalfContainer3TextTitle = "quioscos.halfContainer3TextTitle",
    quioscosHalfContainer3TextDesc = "quioscos.halfContainer3TextDesc",

    // 
    quioscosBackGroundUrlText1Title = "quioscos.backGroundUrlText1Title",
    quioscosBackGroundUrlText1BtnText = "quioscos.backGroundUrlText1BtnText",

}

export default QuioscosEnum