enum TarifasEnum {
    tarifasBannerHeaderTitleItalic = "tarifas.bannerHeaderTitleItalic",
    tarifasBannerHeaderTitleSpan = "tarifas.bannerHeaderTitleSpan",
    tarifasBannerHeaderDesc = "tarifas.bannerHeaderDesc",

    tarifasCardItemRecomend = "tarifas.cardItemRecomend",
    tarifasCardContainerDesc = "tarifas.cardContainerDesc",
    tarifasCardContainerButtonText = "tarifas.cardContainerButtonText",
    tarifasCardContainerExcharge = "tarifas.cardContainerExcharge",

    tarifasCardContainer1Title = "tarifas.cardContainer1Title",
    tarifasCardContainer1ListDesc1 = "tarifas.cardContainer1ListDesc1",
    tarifasCardContainer1ListDesc2 = "tarifas.cardContainer1ListDesc2",
    tarifasCardContainer1ListDesc3 = "tarifas.cardContainer1ListDesc3",

    tarifasCardContainer2Title = "tarifas.cardContainer2Title",
    tarifasCardContainer2ListDesc1 = "tarifas.cardContainer2ListDesc1",
    tarifasCardContainer2ListDesc2 = "tarifas.cardContainer2ListDesc2",
    tarifasCardContainer2ListDesc3 = "tarifas.cardContainer2ListDesc3",
    tarifasCardContainer2ListDesc4 = "tarifas.cardContainer2ListDesc4",

    tarifasCardContainer3Title = "tarifas.cardContainer3Title",
    tarifasCardContainer3ListDesc1 = "tarifas.cardContainer3ListDesc1",
    tarifasCardContainer3ListDesc2 = "tarifas.cardContainer3ListDesc2",
    tarifasCardContainer3ListDesc3 = "tarifas.cardContainer3ListDesc3",
    tarifasCardContainer3ListDesc4 = "tarifas.cardContainer3ListDesc4",

    tarifasCardContainer4Title = "tarifas.cardContainer4Title",
    tarifasCardContainer4Price = "tarifas.cardContainer4Price",
    tarifasCardContainer4ListDesc1 = "tarifas.cardContainer4ListDesc1",
    tarifasCardContainer4ListDesc2 = "tarifas.cardContainer4ListDesc2",
    tarifasCardContainer4ListDesc3 = "tarifas.cardContainer4ListDesc3",
    tarifasCardContainer4ListDesc4 = "tarifas.cardContainer4ListDesc4",



    // 
    tarifasBackGroundUrlText2Title = "tarifas.backGroundUrlText2Title",

    // 
    tarifasCollapseQuestion1 = "tarifas.collapseQuestion1",
    tarifasCollapseAnswer1 = "tarifas.collapseAnswer1",

    tarifasCollapseQuestion2 = "tarifas.collapseQuestion2",
    tarifasCollapseAnswer2 = "tarifas.collapseAnswer2",

    tarifasCollapseQuestion3 = "tarifas.collapseQuestion3",
    tarifasCollapseAnswer3 = "tarifas.collapseAnswer3",

    tarifasCollapseQuestion4 = "tarifas.collapseQuestion4",
    tarifasCollapseAnswer4 = "tarifas.collapseAnswer4",

    tarifasCollapseQuestion5 = "tarifas.collapseQuestion5",
    tarifasCollapseAnswer5 = "tarifas.collapseAnswer5",

    // 表单字段
    tarifasFormContainerTitleSpan1 = "tarifas.formContainerTitleSpan1",
    tarifasFormContainerTitleSpan2 = "tarifas.formContainerTitleSpan2",
    tarifasFormContainerDesc = "tarifas.formContainerDesc",
    // 姓名
    // contactFormContainerForTitleName = "tarifas.formContainerForTitleName",
    // // 姓氏
    // contactFormContainerForTitleSurname = "tarifas.formContainerForTitleSurname",
    // // 电话号码
    // contactFormContainerForTitlePhone = "tarifas.formContainerForTitlePhone",
    // // 西班牙 Spain(España)
    // contactFormContainerForTPlaceholderPhoneArea = "tarifas.formContainerForTPlaceholderPhoneArea",
    //  // 邮件
    //  tarifasFormContainerForTitleEmail = "tarifas.formContainerForTitleEmail",
    // 邮政编码
    tarifasFormContainerForTitlePostalCode = "tarifas.formContainerForTitlePostalCode",
    // 城市
    tarifasFormContainerForTitleCity = "tarifas.formContainerForTitleCity",
    // contactFormContainerForTitleBusinessType = "tarifas.formContainerForTitleBusinessType",

}

export default TarifasEnum