enum CartaDigitalEnum {
    cartaDigitalFirstTitleSpan1 = "cartaDigital.firstTitleSpan1",
    cartaDigitalFirstTitleSpan2 = "cartaDigital.firstTitleSpan2",
    cartaDigitalFirstTitleSpan3 = "cartaDigital.firstTitleSpan3",
    cartaDigitalFirstDesc = "cartaDigital.firstDesc",
    cartaDigitalThirdTitle = "cartaDigital.thirdTitle",
    cartaDigitalThirdDesc = "cartaDigital.thirdDesc",
    // 我想尝试
    tryIt = "cartaDigital.tryIt",
    cartaDigitalFourthTitle = "cartaDigital.fourthTitle",
    cartaDigitalFourthDesc1 = "cartaDigital.fourthDesc1",
    cartaDigitalFourthDesc2 = "cartaDigital.fourthDesc2",
    cartaDigitalFourthDesc3 = "cartaDigital.fourthDesc3",
    cartaDigitalFivethTitle = "cartaDigital.fivethTitle",
    cartaDigitalFivethDesc1 = "cartaDigital.fivethDesc1",
    cartaDigitalFivethDesc2 = "cartaDigital.fivethDesc2",
    cartaDigitalFivethDesc3 = "cartaDigital.fivethDesc3",
    cartaDigitalSixthTitle = "cartaDigital.sixthTitle",
    cartaDigitalSixthDesc1 = "cartaDigital.sixthDesc1",
    cartaDigitalSixthDesc2 = "cartaDigital.sixthDesc2",
    cartaDigitalSixthDesc3 = "cartaDigital.sixthDesc3",
    cartaDigitalSeventhTitle = "cartaDigital.seventhTitle",
    cartaDigitalSeventhDesc1 = "cartaDigital.seventhDesc1",
    cartaDigitalSeventhDesc2 = "cartaDigital.seventhDesc2",
    cartaDigitalSeventhDesc3 = "cartaDigital.seventhDesc3",

    // 背景图
    cartaDigitalEighthTitle = "cartaDigital.eighthTitle",
    // 获取信息
    getMoreInfo = "cartaDigital.getMoreInfo",

}
export default CartaDigitalEnum