enum MarketEnum {
    accurateReach = "cn.accurateReach",
    submitRepurchase = "cn.submitRepurchase",
    promoteRevenueGrowth = "cn.promoteRevenueGrowth",
    flexibleDefinition = "cn.flexibleDefinition",
    defineMembershipLevel = "cn.defineMembershipLevel",
    defineMembershipPoint = "cn.defineMembershipPoint",
    supportSingleMembershipCard = "cn.supportSingleMembershipCard",
    quicklyConvertMember = "cn.quicklyConvertMember",
    rechargeConsume = "cn.rechargeConsume",
    increaseMemberStick = "cn.increaseMemberStick",
    abutmentGroupBuy = "cn.abutmentGroupBuy",
    merchantsAndPlatformCoupons = "cn.merchantsAndPlatformCoupons",
    onlineVerificationCoupons = "cn.onlineVerificationCoupons",
    memberProfile = "cn.memberProfile",
    rfmAnalysis = "cn.rfmAnalysis",
    evaluationAnalysis = "cn.evaluationAnalysis",
}
export default MarketEnum;
