enum ContactEnum {
    contactLeftContainerTitleSpan = "contact.leftContainetitleSpan",
    contactLeftContaineTitleItalic = "contact.leftContaineTitleItalic",
    contactLeftContaineDesc1 = "contact.leftContainedesc1",
    contactLeftContainerListItem1 = "contact.leftContainerListItem1",
    contactLeftContainerListItem2 = "contact.leftContainerListItem2",
    contactLeftContainerListItem3 = "contact.leftContainerListItem3",
    contactLeftContainerListItem4 = "contact.leftContainerListItem4",
    contactLeftContaineDesc2 = "contact.leftContainedesc2",
    contactLeftContainePhone1 = "contact.leftContainerPhone1",
    contactLeftContainePhone2 = "contact.leftContainerPhone2",
    contactLeftContainePhone3 = "contact.leftContainerPhone3",

    // 
    contactBackGroundUrlTextTitle = "contact.backGroundUrlTextTitle",

    // 表单字段
    contactFormContainerTitle = "contact.formContainerTitle",
    contactFormContainerDesc = "contact.formContainerDesc",
    contactFormContainerTips = "contact.formContainerTips",
    // 姓名
    contactFormContainerForTitleName = "contact.formContainerForTitleName",
    // 姓氏
    contactFormContainerForTitleSurname = "contact.formContainerForTitleSurname",
    // 电话区号
    contactFormContainerForTitlePhoneArea = "contact.formContainerForTitlePhoneArea",
    // 电话号码
    contactFormContainerForTitlePhone = "contact.formContainerForTitlePhone",
    // 西班牙 Spain(España)
    contactFormContainerForTPlaceholderPhoneArea = "contact.formContainerForTPlaceholderPhoneArea",
    // 邮件
    contactFormContainerForTitleEmail = "contact.formContainerForTitleEmail",
    // 业务状况
    contactFormContainerForTitleBusinessStatus = "contact.formContainerForTitleBusinessStatus",
    // 业务状况radio group
    // 开放的
    contactFormContainerForBusinessStatusFieldRadio1 = "contactFormContainerForBusinessStatusFieldRadio1",
    // 正在准备中
    contactFormContainerForBusinessStatusFieldRadio2 = "contactFormContainerForBusinessStatusFieldRadio2",
    // 业务类型
    contactFormContainerForTitleBusinessType = "contact.formContainerForTitleBusinessType",
    // 你怎么认识的我们？
    contactFormContainerForTitleChannel = "contact.formContainerForTitleChannel",
    // 我们如何帮助你
    contactFormContainerForTitleHelp = "contact.formContainerForTitleHelp",
    // 
    contactFormContainerForTitleAccept = "contact.formContainerForTitleAccept",
    // 发送
    contactFormContainerForBtnSend = "contact.formContainerForBtnSend",

    // 

    contactRestaurantTypeLabel1 = "contact.RestaurantTypeLabel1",
    contactRestaurantTypeLabel2 = "contact.RestaurantTypeLabel2",
    contactRestaurantTypeLabel3 = "contact.RestaurantTypeLabel3",
    contactRestaurantTypeLabel4 = "contact.RestaurantTypeLabel4",
    contactRestaurantTypeLabel5 = "contact.RestaurantTypeLabel5",
    contactRestaurantTypeLabel6 = "contact.RestaurantTypeLabel6",
    contactRestaurantTypeLabel7 = "contact.RestaurantTypeLabel7",
    contactRestaurantTypeLabel8 = "contact.RestaurantTypeLabel8",
    contactRestaurantTypeLabel9 = "contact.RestaurantTypeLabel9",

    contactchannelTypeLabel1 = "contact.channelTypeLabel1",
    contactchannelTypeLabel2 = "contact.channelTypeLabel2",
    contactchannelTypeLabel3 = "contact.channelTypeLabel3",
    contactchannelTypeLabel4 = "contact.channelTypeLabel4",

    // 必填
    contactRequiredField = "contact.requiredField",




}

export default ContactEnum