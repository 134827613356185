import { Empty } from "antd";
import { ReactNode } from "react";

type IProps = {
  children?: ReactNode;
  desc?: string;
} & React.HTMLAttributes<HTMLDivElement>;
const NoData = (props: IProps) => {
  const { children, className, desc } = props;
  return (
    <div className={`flex w-full h-full items-center justify-center ${className}`}>
      {children || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={desc} />}
    </div>
  );
};

export default NoData;
