enum ChainControlEnum {
    storeControlPlan = "cn.storeControlPlan",
    strengthenBrandValue = "cn.strengthenBrandValue",
    strongAggregateOperation = "cn.strongAggregateOperation",
    improveConsumerExperience = "cn.improveConsumerExperience",
    groupCoordination = "cn.groupCoordination",
    dataShareAndSync = "cn.dataShareAndSync",
    quickExpansionConfig = "cn.quickExpansionConfig",
    bottomLayerConvenientStore = "cn.bottomLayerConvenientStore",
    efficientManageGroupBrand = "cn.EfficientManageGroupBrand",
    strongWeakControl = "cn.strongWeakControl",
    rightsManage = "cn.rightsManage",
    regionManage = "cn.regionManage",
    digitalManage = "cn.digitalManage",
    verticalFlatManage = "cn.verticalFlatManage",
    preciseScienceAnalysis = "cn.preciseScienceAnalysis",
    regionPowerDuty = "cn.regionPowerDuty",
    headOfficeFlexibleCoordination = "cn.headOfficeFlexibleCoordination",
}
export default ChainControlEnum;