// 业务页面路由配置
import { lazy } from "react";
import { IRoute } from "@src/Model/IRoute";
import ProductRoutes from "./ProductRoutes";
import IndustryRoutes from "./IndustryRoutes";

const PCRoutes: IRoute[] = [
    {
        path: "/:lang/home",
        Component: lazy(() => import("@src/Pages/CN/PC/Home")),
    },
    {
        path: "/:lang/download-center",
        Component: lazy(() => import("@src/Pages/CN/PC/DownloadCenter")),
    },
    {
        path: "/:lang/global-service",
        Component: lazy(() => import("@src/Pages/CN/PC/GlobalService")),
    },
    {
        path: "/:lang/company-introduce",
        Component: lazy(() => import("@src/Pages/CN/PC/CompanyIntroduce")),
    },
    ...ProductRoutes,
    ...IndustryRoutes
];

export const PCNoFrameworkRoutes = [
    {
        path: "/protocol",
        Component: lazy(() => import("@src/Pages/CN/PC/ProtocolAndPrivacy/Protocol")),
    },
    {
        path: "/privacy",
        Component: lazy(() => import("@src/Pages/CN/PC/ProtocolAndPrivacy/Privacy")),
    }
]

export default PCRoutes;
