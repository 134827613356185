import willBuildIcon from "@src/assets/CN/images/willBuild.png";
import "./index.less";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function WillBuild() {
  return (
    <div className="PCBuild flex flex-col items-center">
      <img src={willBuildIcon} className="buildIcon" />
      <span className="buildTips">网站升级中，敬请期待</span>
    </div>
  );
}
