/**
 * @description 请求环境枚举
 *  - 用于本地动态切换环境
 * */

import i18n from "./Basic/Language/i18n";

/* eslint-disable no-debugger */
export enum IRequestEnvEnm {
  Dev_CN = "dev_cn",
  Dev_International = "dev_international",
  SEA = "sea",
  CN = "cn",
  Uat = "uat",
  Test = "test",
  Dev = "dev",
  US = "us",
  EU = "eu",
}
class GlobalConfig {
  static instance: GlobalConfig
  protocol = `${location.protocol}//` || 'http://'
  companyDomain = process.env.REACT_APP_COMPANY_DOMAIN || ''

  // 本地代理的 key
  devCNProxy = process.env.REACT_APP_DEV_CN_PROXY_KEY || "";
  devInternationalProxy = process.env.REACT_APP_DEV_INTERNATIONAL_PROXY_KEY || "";
  seaProxy = process.env.REACT_APP_SEA_PROXY_KEY || "";
  cnProxy = process.env.REACT_APP_CN_PROXY_KEY || "";

  // 前端不同环境的域名
  devCNDomain = process.env.REACT_APP_DEV_CN_DOMAIN || "";
  devInternationalDomain = process.env.REACT_APP_DEV_INTERNATIONAL_DOMAIN || "";
  seaDomain = process.env.REACT_APP_SEA_DOMAIN || "";
  cnDomain = process.env.REACT_APP_CN_DOMAIN || "";

  // 开发者工具不同环境的域名
  devOpenAPIDomain = process.env.REACT_APP_DEV_OPENAPI_DOMAIN || "";
  testOpenAPIDomain = process.env.REACT_APP_DEV_INTERNATIONAL_DOMAIN || "";
  OpenAPIDomain = process.env.REACT_APP_OPENAPI_DOMAIN || "";


  InternationalDomainList = [
    "restosuite.ai",
    "www.restosuite.ai",
    "wwwnew.restosuite.ai",
    "wwwdev.restosuite.ai",
  ];

  static getInstance() {
    if (!GlobalConfig.instance) {
      GlobalConfig.instance = new GlobalConfig()
    }
    return GlobalConfig.instance
  }

  // 获取所有代理的 key
  get allProxy() {
    return [
      this.devCNProxy,
      this.devInternationalProxy,
      this.seaProxy,
      this.cnProxy,
    ];
  }

  // 获取当前环境
  // ex: dev
  // ex: demo
  get currentEnv() {
    let env: string | undefined = undefined;
    if (process.env.NODE_ENV === "development") {
      env = sessionStorage.getItem("requestEnv") || IRequestEnvEnm.Dev_CN;
    } else {
      const { origin } = location;
      if (origin.includes(this.devCNDomain)) {
        env = IRequestEnvEnm.Dev_CN;
      } else if (origin.includes(this.devInternationalDomain)) {
        env = IRequestEnvEnm.Dev_International;
      } else if (origin.includes(this.seaDomain)) {
        env = IRequestEnvEnm.SEA;
      } else if (origin.includes(this.cnDomain)) {
        env = IRequestEnvEnm.CN;
      }
    }
    return env;
  }

  setUrlPrefix() {
    // 本地根据环境判断
    if (process.env.NODE_ENV === "development") {
      let prefixUrl = this.devCNProxy
      if (this.currentEnv === IRequestEnvEnm.Dev_International) {
        prefixUrl = this.devInternationalProxy;
      } else if (this.currentEnv === IRequestEnvEnm.SEA) {
        prefixUrl = this.seaProxy;
      } else if (this.currentEnv === IRequestEnvEnm.CN) {
        prefixUrl = this.cnProxy;
      }

      return `/${prefixUrl}`;
    }
    return "";
  }

  get isInternational() {
    if (process.env.NODE_ENV === "development") {
      return localStorage.getItem("isInternational") === "1";
    }
    return this.InternationalDomainList.includes(location.hostname);
  }

  pushToInternational() {
    if (this.isInternational) return
    const targetPath = `/${i18n.language}/home`;
    if (process.env.NODE_ENV === "development") {
      localStorage.setItem("isInternational", "1")
      localStorage.removeItem("languageList")
      window.location.href = targetPath;
    } else {
      if (this.currentEnv === IRequestEnvEnm.Dev_CN) {
        window.location.href = "http://" + this.devInternationalDomain + targetPath;
      } else {
        window.location.href = "https://" + this.seaDomain + targetPath;
      }
    }
  }

  pushToCN() {
    // debugger;
    if (!this.isInternational) return
    const targetPath = `/${i18n.language}/home`;
    if (process.env.NODE_ENV === "development") {
      localStorage.setItem("isInternational", "0")
      localStorage.removeItem("languageList")
      window.location.href = targetPath;
    } else {
      if (this.currentEnv === IRequestEnvEnm.Dev_International) {
        window.location.href = "http://" + this.devCNDomain + targetPath;
      } else {
        window.location.href = "https://" + this.cnDomain + targetPath;
      }
    }
  }

  // 跳转到开发者平台
  pushOpenAPI() {
    if (process.env.NODE_ENV === "development") {
      window.open(this.protocol + this.devOpenAPIDomain, "_blank");
    } else {
      if (this.currentEnv === IRequestEnvEnm.Dev_CN) {
        window.open(this.protocol + this.devOpenAPIDomain, "_blank");
      } else {
        // window.open(this.protocol + this.OpenAPIDomain, "_blank");
      }
    }
  }
}
export default GlobalConfig.getInstance()
