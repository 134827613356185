import { useTranslation } from "react-i18next";
import PoliceIcon from "@src/assets/CN/images/police.png";
import "../index.less";
import CNEnum from "@src/Locales/Constants/CNEnum";

const FooterBottom = () => {
  const { t } = useTranslation();
  const handleNavigate = (path: string) => {
    window.open(path, "_blank");
  };

  return (
    <div className="footerBottom flex justify-between items-center">
      <div className="copyright flex">
        <span>Copyright © 2024 {t(CNEnum.taoquxianCommpany)} |</span>
        <a
          className="text-[#fff9] no-underline mx-[3px]"
          target="_blank"
          href="https://beian.miit.gov.cn/"
        >
          {t(CNEnum.recordNumber)} |
        </a>
        <div className="pliceIcon"></div>
        <a
          className="text-[#fff9] no-underline"
          style={{ fontWeight: 400 }}
          target="_blank"
          href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802044619"
        >
          京公网安备11010802044619号
        </a>
      </div>

      <div className="flex agreement cursor-pointer">
        <span
          onClick={() => {
            handleNavigate("/protocol");
          }}
        >
          {t(CNEnum.serviceAgreement)}
        </span>
        <span
          onClick={() => {
            handleNavigate("/privacy");
          }}
          className="mx-[20PX]"
        >
          {t(CNEnum.privacyPolicy)}
        </span>
        <a
          className="text-[#fff9] no-underline"
          target="_blank"
          href="https://static.restosuite.cn/privacy/insight_privacy.html "
        >
          RestoSuite Insight {t(CNEnum.privacyPolicy)}
        </a>
      </div>
    </div>
  );
};
export default FooterBottom;
