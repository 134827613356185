import { createBrowserRouter } from "react-router-dom";
import Framework from "@src/Components/Framework";
import { GenerateRoutes } from "./GenerateRoutes";
import { IRoute } from "@src/Model/IRoute";
import CNRoutes, { NoFrameworkRoutes } from "./CNRoutes";
import InternationalRoutes from "./InternationalRoutes";
import NotFound from "@src/Basic/NotFound";
import WillBuild from "@src/Basic/WillBuild";
import GlobalConfig from "@src/GlobalConfig";

// 这里判断国内外
const isInternational = GlobalConfig.isInternational;
const childrenRouter = isInternational ? InternationalRoutes : CNRoutes;
const NotFoundComponent = isInternational ? NotFound : WillBuild;
const noFrameworkRoutes = isInternational ? [] : NoFrameworkRoutes;

const routes: IRoute[] = [
  {
    path: "/",
    Component: Framework,
    children: [
      ...childrenRouter,
      {
        path: "*",
        Component: NotFoundComponent,
      },
    ],
  },
  ...noFrameworkRoutes,
];

export default createBrowserRouter(GenerateRoutes(routes));
