import localforage from "localforage";

type ICreateConfig = {
    storeName: string;
    version?: number;
    description?: string;
    driver?: string[];
};

/**
 * @class LocalForageBaseStore
 * @description localforage 的抽象基类,这里的 store 取决于当前使用的子实例
 * @example a 继承了该类,那么 LocalForageBaseStore.store 就是a的localforage实例
 * @example b 继承了该类,那么 LocalForageBaseStore.store 就是b的localforage实例
 *
 * */
class LocalForageBaseStore {
    protected store: LocalForage;
    constructor(config: ICreateConfig) {
        // Create a localforage instance
        this.store = this.createInstance(config);
    }
    createInstance(config: ICreateConfig) {
        return localforage.createInstance({
            name: "rs-official-website",
            driver: [
                localforage.INDEXEDDB,
                localforage.WEBSQL,
                localforage.LOCALSTORAGE,
            ],
            version: 1,
            ...config,
        });
    }

    // 这里只返回了value，没有返回timestamp,理论上外部不需要timestamp
    async get(key: string) {
        if (await this.has(key)) {
            const _item: any = await this.store.getItem(key);
            if (_item) {
                return _item.value;
            }
        }
        return null;
    }

    // 这里多存储了一个timestamp,方便查看存入时间以及后续做过期处理
    async set<T>(key: string, value: T) {
        return await this.store.setItem(key, {
            timestamp: Date.now(),
            value,
        });
    }

    async remove(key: string) {
        return await this.store.removeItem(key);
    }

    clear() {
        return this.store.clear();
    }

    async has(key: string) {
        const keys = await this.store.keys();
        if (keys?.includes(key)) {
            return true;
        }
        return false;
    }
}
export default LocalForageBaseStore;
