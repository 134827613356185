import NotFound from "@src/Basic/NotFound";
import { RouteObject, useRouteError } from "react-router-dom";
import { IRoute } from "@src/Model/IRoute";
import { Button } from "antd";

function ErrorBoundary() {
  const error: any = useRouteError();
  console.log("error", error);
  return (
    <NotFound>
      <div className="w-full h-full flex flex-col items-center justify-center">
        <Button type="primary" onClick={() => window.location.reload()}>
          Reload
        </Button>
      </div>
    </NotFound>
  );
}

export function GenerateRoutes(routes: IRoute[]): RouteObject[] {
  return routes.map((route: IRoute) => {
    const { path, Component, children, meta, loader } = route;
    const _loader = loader;
    return {
      path,
      Component,
      children: (children && GenerateRoutes(children)) || [],
      errorElement: <ErrorBoundary />,
      loader: _loader,
      id: path,
    } as RouteObject;
  });
}
