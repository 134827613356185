/* eslint-disable no-debugger */
import { message } from "antd";
import axios, {
    AxiosError,
    AxiosPromise,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";
import { v4 as uuid } from "uuid";
import { IApiResult } from "@Model/IApiResult";
import GlobalConfig from "@src/GlobalConfig";

//创建axios请求实例
const serviceAxios = axios.create({
    // baseURL: ""
    timeout: 60000,
    withCredentials: true, //跨域是否携带cookie
});

//request拦截器
serviceAxios.interceptors.request.use(
    (config: any) => {
        // traceID 单个请求唯一标识，后端日志可追踪
        config.headers["traceId"] = uuid();
        //   // 设置请求头
        if (!config.headers["content-type"] && !config.isImage) {
            config.headers["content-type"] = "application/json";
        }

        config.url = `${GlobalConfig.setUrlPrefix()}${config.url}`
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// 返回拦截
serviceAxios.interceptors.response.use(
    (res: AxiosResponse) => {
        // TODO: 错误拦截，提示，跳转
        if (res.status === 200) {
            // 如果是文件类型，获取文件，一并返回
            if (Object.prototype.toString.call(res.data) === "[object Blob]") {
                const contentDisposition = decodeURI(
                    res.headers["content-disposition"]
                );
                let fileName = ""
                if (contentDisposition.indexOf("filename=") > -1) {
                    fileName = contentDisposition.substring(
                        contentDisposition.indexOf("filename=") + 10,
                        contentDisposition.length - 1
                    );
                }
                return Promise.resolve({
                    code: "000",
                    fileName,
                    file: res.data,
                });
            }
            //这里是给运营后台的二维码做的，只有data,没有code
            if (res.data && !res.data.code) {
                return Promise.resolve(res.data);
            }

            if (!res.data || res.data == null || res.data.code !== "000") {
                if (res.data) {
                    let errorMsg = res.data.message;
                    if (!errorMsg) {
                        //这里贼蛋疼，供应链接口都写成了这种格式
                        errorMsg = res.data.msg;
                    }
                    if (errorMsg && typeof errorMsg === "string") {
                        message.error(errorMsg);
                    } else {
                        message.error("API occurs some errors");
                    }
                }
                return Promise.reject(res.data ? res.data : res);
            }
            return Promise.resolve(res.data);
        }
        return Promise.resolve({
            code: "701",
            data: null,
            message: "Unknown network error",
        });
    },
    (err: AxiosError) => {
        console.error("axios-errInfo:", err);
        // 请求错误提示语
        let errMsg = "Unknown network error";

        if (err && err.response) {
            const { status } = err.response;
            switch (status) {
                case 400:
                    errMsg = "Error request";
                    break;
                case 401:
                    errMsg =
                        "Session timeout expired, please relogin";
                    break;
                case 403:
                    errMsg = "Denial of access";
                    break;
                case 404:
                    errMsg = "Request error, resource not found";
                    break;
                case 405:
                    errMsg = "Request method not allowed";
                    break;
                case 408:
                    errMsg = "Request timeout";
                    break;
                case 500:
                    errMsg = "Server-side error";
                    break;
                case 501:
                    errMsg = "Network not realized";
                    break;
                case 502:
                    errMsg = "Network error";
                    break;
                case 503:
                    errMsg = "Service unavailable";
                    break;
                case 504:
                    errMsg = "Network timeout";
                    break;
                case 505:
                    errMsg =

                        "The http version does not support this request";
                    break;
                default:
                    errMsg =
                        `Connection error${status}`;
            }
        }

        if (!window.navigator.onLine) {
            errMsg =
                "Network connection has been disconnected, please check the network connectivity";
        }
        message.error(errMsg);
        return Promise.reject(errMsg);
    }
);

const requestEntry = (
    promise: AxiosPromise<IApiResult<any>>,
    noCodeValidate?: boolean
) => {
    return promise.then((r: any) => {
        try {
            if (noCodeValidate) {
                return Promise.resolve(r);
            }
            if (r.code === "000") {
                return Promise.resolve(r);
            } else {
                message.error(r.msg);
                return Promise.resolve(r.msg);
            }
        } catch (e) {
            console.log(e);
        }
    });
};
// 这个返回拦截只校验 data,不校验 code
const requestEntryVerifyData = (promise: AxiosPromise<IApiResult<any>>) => {
    return promise.then((r: any) => {
        try {
            if (r) {
                return Promise.resolve(r);
            } else {
                message.error("error");
                return Promise.resolve(r);
            }
        } catch (e) {
            console.log(e);
        }
    });
};
/**
 * get
 * @param url
 * @param params
 * @param config
 * @returns {Promise}
 */
const get = (
    url: string,
    params?: any,
    config?: AxiosRequestConfig
): Promise<IApiResult<any>> => {
    return requestEntry(serviceAxios.get(url, { params, ...config }));
};

/**
 * post
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 */
const post = (
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    noCodeValidate?: boolean
): Promise<IApiResult<any>> => {
    return requestEntry(serviceAxios.post(url, data, config), noCodeValidate);
};

/**
 * @description 只给公共上传接口使用,24.1.8 这个接口的返回格式没有 code 不能使用通用返回
 * - 不知道是谁的接口,先暂时这么处理,正确做法应该找后端统一格式
 */
const upload = (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
): Promise<IApiResult<any>> => {
    return requestEntryVerifyData(serviceAxios.post(url, data, config));
};
const AxiosRequest = {
    get,
    post,
    upload,
};

export default AxiosRequest;
