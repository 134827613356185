import React, { ReactNode, useMemo } from "react";
import styles from "./index.module.less";
import { useNavigate, useParams } from "react-router-dom";
import useImagesLanguage from "../hooks/useImageLanuage";
import { renderImageItem } from "../utils";
import SVG from "react-inlinesvg";
import i18n from "@src/Basic/Language/i18n";

interface IButtonProps {
  text: string;
  linkTo?: string;
  style?: React.CSSProperties;
  hoverBeforeColor?: string;
  hoverBeforeBackGround?: string;
  hoverAfterColor?: string;
  hoverAfterBackGround?: string;
  isLinkBtn?: boolean;
  btnShowArrowRight?: boolean;
  btnShowArrowRightHoverColor?: string;
}

const arrow = `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.7502 11.0002L14.2199 8.53051C14.5129 8.23761 14.5129 7.76272 14.2199 7.46982L11.7502 5.00009M14.0003 8.00017L3 8.00017" stroke="#4340F3" stroke-width="1.1" stroke-linecap="round"/>
</svg>
`;

const linkArrow = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 16L19.2929 12.7071C19.6834 12.3166 19.6834 11.6834 19.2929 11.2929L16 8M19 12L5 12" stroke="#4340F4" stroke-width="1.5" stroke-linecap="round"/>
</svg>
`;

const HoverButton = (props: IButtonProps) => {
  const {
    text,
    linkTo,
    style,
    hoverBeforeColor,
    hoverBeforeBackGround,
    hoverAfterColor,
    hoverAfterBackGround,
    isLinkBtn,
    btnShowArrowRight,
    btnShowArrowRightHoverColor,
  } = props;

  const { qrorder } = useImagesLanguage();
  const navigator = useNavigate();
  const { lang } = useParams();

  const handleButtonClick = () => {
    navigator(`/${lang}${linkTo}`);
  };

  const renderContent = (type: string) => {
    return btnShowArrowRight ? (
      <label className="flex items-center justify-center gap-1">
        <span>{text}</span>
        <span className={`${styles.svgIcon}`}>
          {type === "button" ? <SVG src={arrow} /> : <SVG src={linkArrow} />}
        </span>
      </label>
    ) : (
      <label>{text}</label>
    );
  };

  return isLinkBtn ? (
    <div className={`${styles.PCLinkButton}`} onClick={handleButtonClick}>
      {renderContent("link")}
    </div>
  ) : (
    <div
      className={`${styles.PCButton}`}
      onClick={handleButtonClick}
      style={
        {
          "--hover-before-background": hoverBeforeBackGround,
          "--hover-before-color": hoverBeforeColor,
          "--hover-after-background": hoverAfterBackGround,
          "--hover-after-color": hoverAfterColor,
          "--hover-svg-color": btnShowArrowRightHoverColor,
          ...style,
        } as React.CSSProperties
      }
      key={"hoverbutton"}
    >
      <div className={`${styles.PCContent}`}>{renderContent("button")}</div>
    </div>
  );
};

export default HoverButton;
