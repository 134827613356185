enum BackOfficeEnum {
    backOfficeBannerHeaderTitleItalic = "backoffice.bannerHeaderTitleItalic",
    backOfficeBannerHeaderDesc = "backOffice.bannerHeaderDesc",

    // todo 这里有两个title
    backOfficeHalfContainer1TextTitle1 = "backOffice.halfContainer1TextTitle1",
    backOfficeHalfContainer1TextTitle2 = "backOffice.halfContainer1TextTitle2",
    backOfficeHalfContainer1TextDesc = "backOffice.halfContainer1TextDesc",

    backOfficeHalfContainer2TextTitle = "backOffice.halfContainer2TextTitle",
    backOfficeHalfContainer2TextDesc = "backOffice.halfContainer2TextDesc",

    backOfficeRowIcon1Name = "backOffice.rowIcon1Name",
    backOfficeRowIcon2Name = "backOffice.rowIcon2Name",
    backOfficeRowIcon3Name = "backOffice.rowIcon3Name",

    backOfficeListIcon1Name = "backOffice.listIcon1Name",
    backOfficeListIcon2Name = "backOffice.listIcon2Name",
    backOfficeListIcon3Name = "backOffice.listIcon3Name",
    backOfficeListIcon4Name = "backOffice.listIcon4Name",
    backOfficeListIcon5Name = "backOffice.listIcon5Name",
    backOfficeListIcon6Name = "backOffice.listIcon6Name",
    backOfficeListIcon7Name = "backOffice.listIcon7Name",
    backOfficeListIcon8Name = "backOffice.listIcon8Name",
    backOfficeListIcon9Name = "backOffice.listIcon9Name",
    backOfficeListIcon10Name = "backOffice.listIcon10Name",

    backOfficeHalfContainer1TextIconListTitle = "backOffice.halfContainer1TextIconListTitle",
    backOfficeHalfContainer1TextIconListItemTitle1 = "backOffice.halfContainer1TextIconListItemTitle1",
    backOfficeHalfContainer1TextIconListItemDesc1 = "backOffice.halfContainer1TextIconListItemDesc1",

    backOfficeHalfContainer1TextIconListItemTitle2 = "backOffice.halfContainer1TextIconListItemTitle2",
    backOfficeHalfContainer1TextIconListItemDesc2 = "backOffice.halfContainer1TextIconListItemDesc2",

    backOfficeHalfContainer1TextIconListItemTitle3 = "backOffice.halfContainer1TextIconListItemTitle3",
    backOfficeHalfContainer1TextIconListItemDesc3 = "backOffice.halfContainer1TextIconListItemDesc3",

    backOfficeHalfContainer1TextIconListItemTitle4 = "backOffice.halfContainer1TextIconListItemTitle4",
    backOfficeHalfContainer1TextIconListItemDesc4 = "backOffice.halfContainer1TextIconListItemDesc4",

    // 

    backOfficeHalfContainer2TextIconListTitle = "backOffice.halfContainer2TextIconListTitle",
    backOfficeHalfContainer2TextIconListItemTitle1 = "backOffice.halfContainer2TextIconListItemTitle1",
    backOfficeHalfContainer2TextIconListItemDesc1 = "backOffice.halfContainer2TextIconListItemDesc1",

    backOfficeHalfContainer2TextIconListItemTitle2 = "backOffice.halfContainer2TextIconListItemTitle2",
    backOfficeHalfContainer2TextIconListItemDesc2 = "backOffice.halfContainer2TextIconListItemDesc2",

    backOfficeHalfContainer2TextIconListItemTitle3 = "backOffice.halfContainer2TextIconListItemTitle3",
    backOfficeHalfContainer2TextIconListItemDesc3 = "backOffice.halfContainer2TextIconListItemDesc3",


    // 
    backOfficeHalfContainer5TextTitle1 = "backOffice.halfContainer5TextTitle1",
    backOfficeHalfContainer5TextDesc1 = "backOffice.halfContainer5TextDesc1",

    backOfficeHalfContainer5TextTitle2 = "backOffice.halfContainer5TextTitle2",
    backOfficeHalfContainer5TextDesc2 = "backOffice.halfContainer5TextDesc2",

    backOfficeHalfContainer5TextTitle3 = "backOffice.halfContainer5TextTitle3",
    backOfficeHalfContainer5TextDesc3 = "backOffice.halfContainer5TextDesc3",

    backOfficeHalfContainer5TextTitle4 = "backOffice.halfContainer5TextTitle4",
    backOfficeHalfContainer5TextDesc4 = "backOffice.halfContainer5TextDesc4",

    // 


    backOfficeBackGroundUrlText2Title = "backOffice.backGroundUrlText2Title",



    rsInsightAppCardDesc = "backOffice.rsInsightAppCardDesc",

    qrOrderCardDesc = "backOffice.qrOrderCardDesc",

}

export default BackOfficeEnum