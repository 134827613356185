// reservas
enum ReservasEnum {
    reservasFirstTitle = "reservas.firstTitle",
    reservasFirstTitleSpan = "reservas.firstTitleSpan",
    reservasFirstDesc = "reservas.firstDesc",
    reservasSecondTitle = "reservas.secondTitle",
    reservasSecondDesc = "reservas.secondDesc",
    reservasFourthTitle = "reservas.fourthTitle",
    reservasFourthDesc = "reservas.fourthDesc",
    reservasFivethRow = "reservas.fivethRow",
    reservasFivethTitle = "reservas.reservasFivethTitle",
    reservasFivethDesc1 = "reservas.fivethDesc1",
    reservasFivethDesc2 = "reservas.fivethDesc2",
    reservasFivethDesc3 = "reservas.fivethDesc3",
    reservasSixthTitle = "reservas.sixthTitle",

    tpvCardDesc = "qroder.tpvCardDesc",
}

export default ReservasEnum