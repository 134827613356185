enum ContactModalEnum {
    leaveContactInfo = "cn.leaveContactInfo",
    corporateName = "cn.corporateName",
    contactPerson = "cn.contactPerson",
    contactPhone = "cn.contactPhone",
    contactMailbox = "cn.contactMailbox",
    contactAddress = "cn.contactAddress",
    streetName = "cn.streetName",
    province = "cn.province",
    municipality = "cn.municipality",
    area = "cn.area",
    postalCode = "cn.postalCode",
    businessFormat = "cn.businessFormat",
    isChain = "cn.isChain",
    close = "cn.close",
    submit = "cn.submit",
    pleaseInput = "cn.pleaseInput",
    pleaseSelect = "cn.pleaseSelect",
    pleaseSelectProvince = "cn.pleaseSelectProvince",
    pleaseSelectCity = "cn.pleaseSelectCity",
    pleaseInputPhone = "cn.pleaseInputPhone",
    submitSuccess = "cn.submitSuccess",
    noLabel = "cn.no",
    yesLabel = "cn.yes",
    cafeLabel = "cn.cafe",
    comprehensive = "cn.comprehensive",
    bakingShop = "cn.bakingShop",
    houseNumber = "cn.houseNumber",
}

export default ContactModalEnum;