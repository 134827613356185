enum SupplyChainEnum {
    easilyImproveEfficiency = "cn.easilyImproveEfficiency",
    efficientCollaboration = "cn.efficientCollaboration",
    storeInventory = "cn.storeInventory",
    timelyFindProblem = "cn.timelyFindProblem",
    unifyProcess = "cn.unifyProcess",
    supportStoreInventory = "cn.supportStoreInventory",
    storeDemandGoods = "cn.storeDemandGoods",
    approveShipment = "cn.approveShipment",
    optimalProcess = "cn.optimalProcess",
    storeCostManage = "cn.storeCostManage",
    supportBrandDiff = "cn.supportBrandDiff",
    supportAutoDeduce = "cn.supportAutoDeduce",
    calculateCostDiff = "cn.calculateCostDiff",
    deliverySupplyChain = "cn.deliverySupplyChain",
    directDeliveryDistribution = "cn.directDeliveryDistribution",
    centralManage = "cn.CentralManage",
}
export default SupplyChainEnum;