enum RsInsightAppEnum {
    rsInsightAppBannerHeaderTitleItalic = "rsInsightApp.bannerHeaderTitleItalic",
    rsInsightAppBannerHeaderTitleSpan = "rsInsightApp.bannerHeaderTitleSpan",
    rsInsightAppBannerHeaderDesc = "rsInsightApp.bannerHeaderDesc",

    // 
    rsInsightAppHalfContainer1TextTitle = "rsInsightApp.halfContainer1TextTitle",
    rsInsightAppHalfContainer1TextDesc = "rsInsightApp.halfContainer1TextDesc",

    // 
    rsInsightAppProductsCardTitle1 = "rsInsightApp.productsCardTitle1",
    rsInsightAppProductsCardDesc1 = "rsInsightApp.productsCardDesc1",

    rsInsightAppProductsCardTitle2 = "rsInsightApp.productsCardTitle2",
    rsInsightAppProductsCardDesc2 = "rsInsightApp.productsCardDesc2",

    rsInsightAppProductsCardTitle3 = "rsInsightApp.productsCardTitle3",
    rsInsightAppProductsCardDesc3 = "rsInsightApp.productsCardDesc3",

    rsInsightAppProductsCardTitle4 = "rsInsightApp.productsCardTitle4",
    rsInsightAppProductsCardDesc4 = "rsInsightApp.productsCardDesc4",

    rsInsightAppProductsCardTitle5 = "rsInsightApp.productsCardTitle5",
    rsInsightAppProductsCardDesc5 = "rsInsightApp.productsCardDesc5",

    rsInsightAppProductsCardTitle6 = "rsInsightApp.productsCardTitle6",
    rsInsightAppProductsCardDesc6 = "rsInsightApp.productsCardDesc6",


    // 
    rsInsightAppBackGroundUrlText2Title = "rsInsightApp.backGroundUrlText2Title",

    // 


}

export default RsInsightAppEnum