import CNEnum from "@src/Locales/Constants/CNEnum";
import { CommonEnum } from "@src/Locales/Constants/CommonEnum";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useNavList = () => {
  const { t, i18n } = useTranslation();
  const navList = useMemo(() => {
    return [
      {
        title: t(CNEnum.home),
        key: "home",
        path: "/home",
      },
      {
        title: t(CNEnum.product),
        key: "product",
        path: "/product",
        chevron: "down",
        content: [
          {
            title: t(CNEnum.efficientOperation),
            path: "/product/efficient", // 页面路径
            list: [
              {
                label: `KIOSK（${t(CommonEnum.largeScreenOrder)}）`,
                anchor: "kiosk",
                key: "kiosk",
              },
              { label: `POS（ ${t(CNEnum.cashierSystem)}）`, anchor: "pos", key: "pos" },
              { label: `KDS（${t(CNEnum.kitchenProduceSystem)}）`, anchor: "kds", key: "kds" },
              { label: t(CNEnum.handheldOrder), anchor: "handleOrder", key: "handleOrder" },
              { label: t(CNEnum.electronicMenu), anchor: "electronicMenu", key: "electronicMenu" },
              {
                label: t(CNEnum.intelligentCalling),
                anchor: "intelligentCall",
                key: "intelligentCall",
              },
            ],
          },
          {
            title: t(CNEnum.onlineSmartRestaurant),
            path: "/product/online-restaurant", // 页面路径
            list: [
              { label: t(CNEnum.h5AndApplet), anchor: "onlineSystem", key: "onlineSystem" },
              { label: t(CNEnum.aggregateTakeout), anchor: "takeaway", key: "takeaway" },
              { label: t(CNEnum.onLinePayment), anchor: "onlinePayment", key: "onlinePayment" },
              {
                label: t(CNEnum.electronicInvoice),
                anchor: "invoiceTax",
                type: "invoice",
                key: "invoiceTaxinvoice",
              },
              {
                label: t(CNEnum.taxAdmin),
                anchor: "invoiceTax",
                type: "tax",
                key: "invoiceTaxtax",
              },
              { label: t(CNEnum.predetermine), anchor: "preBook", key: "preBook" },
              { label: t(CNEnum.intelligentQueue), anchor: "lineUp", key: "lineUp" },
              { label: t(CNEnum.evaluateInteraction), anchor: "evaluate", key: "evaluate" },
              {
                label: t(CNEnum.businessPlatformTicket),
                anchor: "businessTicket",
                key: "businessTicket",
              },
            ],
          },
          {
            title: t(CNEnum.smartMarketing),
            path: "/product/marketing", // 页面路径
            list: [
              { label: t(CNEnum.marketingActivities), anchor: "activities", key: "activities" },
              { label: t(CNEnum.memberManage), anchor: "memberManage", key: "memberManage" },
              {
                label: t(CNEnum.intelligentStoredValue),
                anchor: "intelligentStored",
                key: "intelligentStored",
              },
              { label: t(CNEnum.groupBuying), anchor: "gruopBuy", key: "gruopBuy" },
              { label: t(CNEnum.ticket), anchor: "ticket", key: "ticket" },
              { label: t(CNEnum.memberAnalysis), anchor: "memberAnalysis", key: "memberAnalysis" },
            ],
          },
          {
            title: t(CNEnum.supplyChain),
            path: "/product/supply-chain", // 页面路径
            list: [
              { label: t(CNEnum.inventory), anchor: "storeInventory", key: "storeInventory" },
              { label: t(CNEnum.procure), anchor: "procure", key: "procure" },
              { label: t(CNEnum.cost), anchor: "costControl", key: "costControl" },
              { label: t(CNEnum.delivery), anchor: "delivery", key: "delivery" },
            ],
          },
          {
            title: t(CNEnum.manageAndDecision),
            path: "/product/manage-decision", // 页面路径
            list: [
              { label: t(CommonEnum.numberSpeak), anchor: "numberSpeak", key: "numberSpeak" },
              {
                label: t(CNEnum.merchantManagePlatform),
                anchor: "manageBackend",
                key: "manageBackend",
              },
              {
                label: t(CNEnum.efficientSupportTools),
                anchor: "supportTools",
                key: "supportTools",
              },
              { label: t(CNEnum.international), anchor: "international", key: "international" },
              { label: t(CNEnum.masterDataManage), anchor: "numberSpeak", key: "" },
              {
                label: t(CNEnum.integrationCenter),
                anchor: "integratedCenter",
                key: "integratedCenter",
              },
            ],
          },
          {
            title: t(CNEnum.chainControl),
            path: "/product/chain-control", // 页面路径
            list: [
              {
                label: t(CNEnum.multiOrganizeControl),
                anchor: "efficientLinkage",
                key: "efficientLinkage",
              },
              {
                label: t(CNEnum.chainCoordination),
                anchor: "chainCoordination",
                key: "chainCoordination",
              },
              {
                label: t(CNEnum.flexibleOperation),
                anchor: "quickExpansion",
                key: "quickExpansion",
              },
              {
                label: t(CNEnum.efficientManage),
                anchor: "efficientManage",
                key: "efficientManage",
              },
            ],
          },
        ],
      },
      {
        title: t(CNEnum.industry),
        key: "industry",
        path: "/industry",
        chevron: "down",
        noNextLevel: true,
        content: [
          {
            title: t(CNEnum.fastFood),
            path: "/industry/fast-food", // 页面路径
            list: [],
          },
          {
            title: t(CNEnum.dinner),
            path: "/industry/dinner", // 页面路径
            list: [],
          },
          {
            title: t(CNEnum.teaDrink),
            path: "/industry/tea-drinks", // 页面路径
            list: [],
          },
        ],
      },
      {
        title: t(CNEnum.customer),
        key: "custom",
        path: "/custom",
      },
      // {
      //   title: t(CNEnum.study),
      //   key: "study",
      //   path: "/study",
      // },
      // {
      //   title: t(CNEnum.cooperativePartner),
      //   key: "partner",
      //   path: "/partner",
      // },
      {
        title: t(CNEnum.companyIntroduction),
        key: "company-introduce",
        path: "/company-introduce",
      },
      {
        title: t(CNEnum.downloadCenter),
        key: "download-center",
        path: "/download-center",
      },
    ];
  }, [i18n.language]);

  return {
    navList,
  };
};

export default useNavList;
