import { IKVProps } from "@src/Model/IKVProps"
import AxiosRequest from "@src/Utils/axios"
import internationalPackageAxiosRequest from '@src/Utils/internationPackageAxios'

export function queryLanguageResource(params: IKVProps = {}) {
  return AxiosRequest.post('/websiteApi/operation-manager/international/language/v2/listCommonMoreLanguage', params)
}
export function queryLanguageList(params: IKVProps = {}) {
  return AxiosRequest.post('/websiteApi/operation-manager/dictionary/listLanguage', params)
}
export function getPackagesList(params: IKVProps = {}) {
  return AxiosRequest.post('/packages/list', params)
}
// export function getRegionPackagesListDev(params: IKVProps = {}, region?: string) {
//   return AxiosRequest.post('/_localReqRP/packages/list', params)
// }
export function getRegionPackagesList(region: string, params: IKVProps = {},) {
  // 默认是新加坡的
  const _rigion = region === "sea" ? "" : region
  return internationalPackageAxiosRequest.post(`/http://packages.${_rigion}.restosuite.ai/packages/list`, params)
}
