// 业务页面路由配置
import { lazy } from "react";
import { IRoute } from "@src/Model/IRoute";

const MobileRoutes: IRoute[] = [
    {
        path: "/:lang/home",
        Component: lazy(() => import("@src/Pages/International/PC/Home")),
    },
    {
        path: "/:lang/qr-order",
        Component: lazy(() => import("@src/Pages/International/PC/QROrder")),
    },
    {
        path: "/:lang/reservas",
        Component: lazy(() => import("@src/Pages/International/PC/Reservas")),
    },
    {
        path: "/:lang/carta-digital",
        Component: lazy(() => import("@src/Pages/International/PC/CartaDigital")),
    },
    {
        path: "/:lang/tpv",
        Component: lazy(() => import("@src/Pages/International/PC/Tpv")),
    },
    {
        path: "/:lang/back-office",
        Component: lazy(() => import("@src/Pages/International/PC/BackOffice")),
    },
    {
        path: "/:lang/member",
        Component: lazy(() => import("@src/Pages/International/PC/Member")),
    },
    {
        path: "/:lang/marketing",
        Component: lazy(() => import("@src/Pages/International/PC/Marketing")),
    },
    {
        path: "/:lang/tarifas",
        Component: lazy(() => import("@src/Pages/International/PC/Tarifas")),
    },
    {
        path: "/:lang/contact",
        Component: lazy(() => import("@src/Pages/International/PC/Contact")),
    },
    {
        path: "/:lang/rs-insight-app",
        Component: lazy(() => import("@src/Pages/International/PC/RsInsightApp")),
    },
    {
        path: "/:lang/kds",
        Component: lazy(() => import("@src/Pages/International/PC/Kds")),
    },
    {
        path: "/:lang/quioscos",
        Component: lazy(() => import("@src/Pages/International/PC/Quioscos")),
    },
    {
        path: "/:lang/resto-para",
        Component: lazy(() => import("@src/Pages/International/PC/RestoPara")),
    },
    {
        path: "/:lang/sobre-nosotros",
        Component: lazy(() => import("@src/Pages/International/PC/SobreNosotros")),
    },
    {
        path: "/:lang/gestiony-control",
        Component: lazy(() => import("@src/Pages/International/PC/GestionyControl")),
    },
    {
        path: "/:lang/delivery-takeout",
        Component: lazy(() => import("@src/Pages/International/PC/DeliveryUnionTakeout")),
    },
    {
        path: "/:lang/download-center",
        Component: lazy(() => import("@src/Pages/International/PC/DownLoadCenter")),
    },
    {
        path: "/:lang/es-legal-notice",
        Component: lazy(() => import("@src/Pages/International/PC/EsLegalCookiesPrivate/LegalNotice")),
    },
    {
        path: "/:lang/es-cookie",
        Component: lazy(() => import("@src/Pages/International/PC/EsLegalCookiesPrivate/Cookies")),
    },
    {
        path: "/:lang/es-private",
        Component: lazy(() => import("@src/Pages/International/PC/EsLegalCookiesPrivate/Private")),
    },
    {
        path: "/:lang/en-legal-notice",
        Component: lazy(() => import("@src/Pages/International/PC/EnLegalCookiesPrivate/LegalNotice")),
    },
    {
        path: "/:lang/en-cookie",
        Component: lazy(() => import("@src/Pages/International/PC/EnLegalCookiesPrivate/Cookies")),
    },
    {
        path: "/:lang/en-private",
        Component: lazy(() => import("@src/Pages/International/PC/EnLegalCookiesPrivate/Private")),
    },
    {
        path: "/:lang/global-service",
        Component: lazy(() => import("@src/Pages/International/PC/GlobalService")),
    },
];

export default MobileRoutes;
