import { LanguageContextProvider } from "@src/Basic/Language/Provider.tsx";
import { RouterProvider } from "react-router-dom";
import router from "@src/Router/index";
import { queryLanguageResource } from "./Api/Common/CommonApi";
import { useEffect, useState } from "react";
import { useStore } from "./Store";
import { IKVProps } from "./Model/IKVProps";
import LanguageLocalForageStore from "./Localforage/LanguageLocalForageStore";
import Emitter from "./Utils/Emitter";
import Loading from "./Basic/Loading";
import i18n, { i18nAddResource } from "./Basic/Language/i18n";
import GlobalConfig, { IRequestEnvEnm } from "./GlobalConfig";
import semver from "semver";
import VConsole from "vconsole";
import { customLanguageSort } from "./Utils/CommonUtils";
import _ from "lodash";
import { languageValueToRealLangMap } from "./Basic/Language/const";
import { initReactI18next } from "react-i18next";

function App() {
  const { commonStore } = useStore();
  // TODO 默认 loading,每次刷新都获取多语
  const [languageLoading, setLanguageLoading] = useState(false);
  useEffect(() => {
    Emitter.on("languageLoad", (status: boolean) => {
      console.log("languageLoad", status, Date.now().toString());
      setLanguageLoading(status);
    });
    return () => {
      Emitter.offAll("languageLoad");
    };
  }, []);

  const getLanguageList = () => {
    let languageList = [
      {
        label: "Australia(English)",
        value: "en_au",
        isForeign: true,
      },
      {
        label: "Canada(English)",
        value: "en_ca",
        isForeign: true,
      },
      // {
      //   label: "Deutschland(Deutsch)",
      //   value: "de_de",
      //   isForeign: true,
      // },
      {
        label: "Deutschland(English)",
        value: "en_de",
        isForeign: true,
      },
      {
        label: "España(Español)",
        value: "es_es",
        isForeign: true,
      },
      // {
      //   label: "Italia(Italiano)",
      //   value: "it_it",
      //   isForeign: true,
      // },
      {
        label: "Singapore(English)",
        value: "en_sg",

        isForeign: true,
      },
      {
        label: "United Kingdom(English)",
        value: "en_gb",
        isForeign: true,
      },
      {
        label: "United States(English)",
        value: "en_us",
        isForeign: true,
      },
      {
        label: "中国(简体中文)",
        value: "zh_cn",
      },
      {
        label: "台湾(中国)(繁體中文)",
        value: "zh_tw",
      },
    ];

    if (GlobalConfig.isInternational) {
      languageList = [
        {
          label: "Australia(English)",
          value: "en_au",
        },
        {
          label: "Canada(English)",
          value: "en_ca",
        },
        // {
        //   label: "Deutschland(Deutsch)",
        //   value: "de_de",
        // },
        {
          label: "Deutschland(English)",
          value: "en_de",
        },
        {
          label: "España(Español)",
          value: "es_es",
        },
        // {
        //   label: "Italia(Italiano)",
        //   value: "it_it",
        // },
        {
          label: "Singapore(English)",
          value: "en_sg",
        },
        {
          label: "United Kingdom(English)",
          value: "en_gb",
        },
        {
          label: "United States(English)",
          value: "en_us",
        },
        {
          label: "中国(简体中文)",
          value: "zh_cn",
          isForeign: true,
        },
        {
          label: "台湾(中国)(繁體中文)",
          value: "zh_tw",
          isForeign: true,
        },
      ];
    }

    const _sortLanguages = customLanguageSort(languageList);
    commonStore.setLanguageList(_sortLanguages);
    getLanguageResource(_sortLanguages);
  };

  const getLanguageResource = (languageList: IKVProps[]) => {
    Emitter.emit("languageLoad", true);
    const params: IKVProps = {
      namespace: "OfficialWebsite",
      languageCodes: _.uniq(_.values(languageValueToRealLangMap)),
    };
    queryLanguageResource(params)
      .then((languageResult) => {
        if (languageResult?.code !== "000") return;
        LanguageLocalForageStore.set("language", languageResult.data || []).then(() => {
          i18nAddResource(true);
        });
        // 记录获取的时间, 用于判断是否需要重新获取
        localStorage.setItem("languageTime", Date.now().toString());
        // 记录获取的版本，用于判断是否需要重新获取
        localStorage.setItem("version", process.env.REACT_APP_VERSION);
      })
      .catch(() => {
        Emitter.emit("languageLoad", false);
      });
  };

  const getProdLanguage = async () => {
    // 非开发环境, 如果超过 24 小时或者修改了版本号, 都要重新获取多语
    // 每次发版前修改了多语，都需要改大package.json的版本号
    const version = localStorage.getItem("version");
    const languageTime = localStorage.getItem("languageTime");
    const hasMemory = await LanguageLocalForageStore.get("language");
    if (!hasMemory || !version || semver.gt(process.env?.REACT_APP_VERSION, version)) {
      // 比较package.json的版本号，获取最新多语
      getLanguageList();
    } else if (languageTime) {
      // 如果超过 24 小时, 重新获取多语
      const now = Date.now();
      const diff = now - parseInt(languageTime);
      if (diff > 24 * 60 * 60 * 1000) {
        getLanguageList();
      } else {
        i18nAddResource(true);
      }
    } else {
      getLanguageList();
    }
  };
  useEffect(() => {
    getLanguageList();
    // if (
    //   GlobalConfig.currentEnv === IRequestEnvEnm.Dev_CN ||
    //   GlobalConfig.currentEnv === IRequestEnvEnm.Dev_International
    // ) {
    //   // 开发环境实时获取多语
    //   getLanguageList();
    //   new VConsole();
    // } else {
    //   Emitter.emit("languageLoad", true);
    //   LanguageLocalForageStore.get("language")
    //     .then((res) => {
    //       if (!res) {
    //         getProdLanguage();
    //       } else {
    //         i18nAddResource(true);
    //       }
    //     })
    //     .catch(() => {
    //       getProdLanguage();
    //     });
    // }
  }, []);

  return (
    <div className="w-screen h-screen">
      <LanguageContextProvider>
        {languageLoading ? <Loading /> : <RouterProvider router={router} />}
      </LanguageContextProvider>
    </div>
  );
}

export default App;
