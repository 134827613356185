import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ChevronDonw } from "@src/assets/CN/svg/chevron-donw.svg";
import { ReactComponent as ChevronUp } from "@src/assets/CN/svg/chevron-up.svg";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useNavList from "./useNavList";
import _ from "lodash";
import i18n from "@src/Basic/Language/i18n";
import { isMobile } from "react-device-detect";

const NavList = () => {
  const { pathname, state } = useLocation();
  const { navList } = useNavList();
  const [currentTab, setCurrentTab] = useState<string>("");
  const [activeNav, setActiveNav] = useState<string>("");
  const [activeLabel, setActiveLabel] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { lang = "" } = useParams();
  const i18Language = useMemo(() => i18n.language, [i18n.language]);

  const path = useMemo(() => {
    return pathname.replace(`/${lang}`, "");
  }, [pathname]);

  useEffect(() => {
    const key = lang ? path.split("/")[1] : pathname.split("/")[2];
    setCurrentTab(_.find(data, { key })?.key);
  }, [data, path]);

  useEffect(() => {
    setActiveNav(_.find(_.find(data, { key: currentTab })?.content, { path })?.path);
  }, [data, path, currentTab]);

  useEffect(() => {
    const type = search.get("type");

    setActiveLabel(
      _.find(_.find(_.find(data, { key: currentTab })?.content, { path })?.list, {
        key: `${state?.anchor}${type || ""}`,
      })?.key
    );
  }, [data, path, currentTab, state, search]);

  useEffect(() => {
    setData(navList);
  }, [navList]);

  const handleChevron = (key: string, chevron: string) => {
    const newData = data.map((item) => {
      if (item.chevron && item.key === key) {
        item.chevron = chevron;
      } else {
        item.chevron && (item.chevron = "down");
      }
      return item;
    });
    setData(newData);
  };

  const handleNavigate = (item: any) => {
    navigate(`/${lang || i18Language}${item.path}`);
  };

  const handleTabNav = (item: any) => {
    const { path, key, content } = item;
    handleChevron(key, item.chevron === "up" ? "down" : "up");
    if (!content?.length) {
      !content?.length && navigate(`/${lang || i18Language}${path}`);
      key && setCurrentTab(key);
    }
  };

  const handleNavAnchor = (path: string, anchor: string, type?: string) => {
    const search = type ? `?type=${type}` : "";
    navigate(`/${lang}${path}${search}`, { state: { anchor } });
  };

  return (
    <div className="PCNavList flex h-full">
      {data.map((item: any) => {
        return (
          <div
            className="h-full relative"
            key={item.key}
            onMouseEnter={(e) => {
              if (isMobile) {
                return;
              }
              handleChevron(item.key, "up");
            }}
            onMouseLeave={(e) => {
              if (isMobile) {
                return;
              }
              handleChevron(item.key, "down");
            }}
            onClick={(e) => {
              if (isMobile && item.chevron) {
                handleChevron(item.key, item.chevron === "up" ? "down" : "up");
              } else {
                handleTabNav(item);
              }
            }}
          >
            {item.chevron === "up" && (
              <div
                id="navContentWrap"
                className={`navWrap flex ${item.noNextLevel ? "flex-col" : ""}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {item.content.map((navContent: any, index: number) => {
                  return (
                    <div className="navContent" key={index}>
                      {navContent.title && (
                        <nav
                          className={`navTitle cursor-pointer ${
                            item.noNextLevel ? "navBlackTitle" : ""
                          } ${activeNav === navContent.path ? "activeNavOrLabel" : ""}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNavigate(navContent);
                            handleChevron(item.key, "down");
                          }}
                        >
                          {navContent.title}
                        </nav>
                      )}
                      {navContent.list.map((nav: any, idx: number) => {
                        return (
                          <nav
                            className={`navLabel cursor-pointer ${
                              activeLabel === nav.key ? "activeNavOrLabel" : ""
                            }`}
                            key={idx}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleNavAnchor(navContent.path, nav.anchor, nav.type);
                              handleChevron(item.key, "down");
                            }}
                          >
                            {nav.label}
                          </nav>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
            <div
              id={`tabTitle${item.key}`}
              className={`tabTitle h-full flex items-center cursor-pointer ${
                currentTab === item.key ? "activeTab" : ""
              }`}
            >
              <span>{item.title}</span>
              {item.chevron && (
                <ChevronDonw
                  className={`ml-[6PX] ${item.chevron === "up" ? "chevronUp" : "chevronDown"}`}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NavList;
