enum TPVEnum {
    tpvFirstTitleSpanItalic = "tpv.firstTitleSpanItalic",
    tpvFirstTitleSpan = "tpv.firstTitleSpan",
    tpvFirstDesc = "tpv.firstDesc",

    tpvSecondTitleP1 = "tpv.secondTitleP1",
    tpvSecondTitleP2 = "tpv.secondTitleP2",
    // 
    tpvFourthTitle = "tpv.fourthTitle",
    tpvFourthDesc = "tpv.fourthDesc",
    // 
    tpvFivethTitle = "tpv.fivethTitle",
    // 
    tpvFiveth1Title1 = "tpv.fiveth1Title1",
    tpvFiveth1Desc1 = "tpv.fiveth1Desc1",
    tpvFiveth1Desc2 = "tpv.fiveth1Desc2",
    tpvFiveth1Desc3 = "tpv.fiveth1Desc3",

    // 
    tpvFiveth2Title = "tpv.fiveth2Title",
    tpvFiveth2Desc = "tpv.fiveth2Desc",

    // 
    tpvFiveth3Title = "tpv.fiveth3Title",
    tpvFiveth3Desc = "tpv.fiveth3Desc",

    tpvSixthTitle = "tpv.sixthTitle",



    memberCardTitle = "tpv.memberCardTitle",
    memberCardDesc = "tpv.memberCardDesc",

}

export default TPVEnum