enum EfficientEnum {
    superIntegrationSaas = "cn.superIntegrationSaas",
    oneStopManageTool = "cn.oneStopManageTool",
    flexibleInStoreOrder = "cn.flexibleInStoreOrder",
    supportLargeScreenOrder = "cn.supportLargeScreenOrder",
    supportSelfAndDine = "cn.supportSelfAndDine",
    supportOrderMemberSettlement = "cn.supportOrderMemberSettlement",
    comprehensiveCashierSystem = "cn.comprehensiveCashierSystem",
    supportOrderAndCashierMode = "cn.supportOrderAndCashierMode",
    allCostCalculation = "cn.allCostCalculation",
    paymentMethodsCombine = "cn.PaymentMethodsCombine",
    supprtPayFirstOrLater = "cn.supprtPayFirstOrLater",
    intelligentKitchen = "cn.intelligentKitchen",
    digitalizeProduce = "cn.digitalizeProduce",
    realTimeVoiceRemind = "cn.realTimeVoiceRemind",
    orderRealTimeSync = "cn.orderRealTimeSync",
    reduceMissedOrLost = "cn.reduceMissedOrLost",
    reminderOverdue = "cn.reminderOverdue",
    AdjustMakeDeliveryOrder = "cn.AdjustMakeDeliveryOrder",
    refinedRestaurantManage = "cn.refinedRestaurantManage",
    intelligentMergeSchedule = "cn.intelligentMergeSchedule",
    passDishes = "cn.passDishes",
    byDishOrderPassDishes = "cn.byDishOrderPassDishes",
    handheldQuickOrder = "cn.handheldQuickOrder",
    LANConnectPOS = "cn.LANConnectPOS",
    handheldOrderPayment = "cn.handheldOrderPayment",
    cardVerificationPreferential = "cn.cardVerificationPreferential",
    handheldMenuForCustom = "cn.handheldMenuForCustom",
    customerOrderToPOS = "cn.customerOrderToPOS",
    selectMenuByTable = "cn.selectMenuByTable",
    multiCallNumberNoticeMethods = "cn.multiCallNumberNoticeMethods",
    byOrderMakeWholeDishCut = "cn.byOrderMakeWholeDishCut",
    orderStatusAutoChange = "cn.orderStatusAutoChange",
    nationalCateringSolutions = "cn.nationalCateringSolutions",
}
export default EfficientEnum;