enum ManageDecisionEnum {
    personalOrderingNeeds = "cn.personalOrderingNeeds",
    globalBusinessExpansion = "cn.globalBusinessExpansion",
    crossMultiplePlatforms = "cn.crossMultiplePlatforms",
    improveSolution = "cn.improveSolution",
    globalExpansion = "cn.globalExpansion",
    fullDataInsightAnalysis = "cn.fullDataInsightAnalysis",
    builtInAnalysisTemplate = "cn.builtInAnalysisTemplate",
    userDefinedConfig = "cn.userDefinedConfig",
    multiDimensionsView = "cn.multiDimensionsView",
    defineProductSales = "cn.defineProductSales",
    strongControlMode = "cn.strongControlMode",
    strengthenImproveValue = "cn.strengthenImproveValue",
    quicklyCashierConfig = "cn.quicklyCashierConfig",
    supportTools = "cn.supportTools",
    autoApplyUpdateVersion = "cn.autoApplyUpdateVersion",
    valueAddServiceOpen = "cn.valueAddServiceOpen",
    accurateTranslation = "cn.accurateTranslation",
    oneClickSettings = "cn.oneClickSettings",
    supportDiffRegionFormat = "cn.supportDiffRegionFormat",
    presetCommonConnector = "cn.presetCommonConnector",
    dataDevelopCapacity = "cn.dataDevelopCapacity",

}
export default ManageDecisionEnum;
