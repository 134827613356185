enum IndustryEnum {
    simplifyOrderStrengthenManage = "cn.simplifyOrderStrengthenManage",
    painPointsFastCustomers = "cn.painPointsFastCustomers",
    painPointsTeaCustomers = "cn.painPointsTeaCustomers",
    painPointsDinnerCustomers = "cn.painPointsDinnerCustomers",
    orderCheckoutFastConvenient = "cn.orderCheckoutFastConvenient",
    fasterMoreEfficient = "cn.fasterMoreEfficient",
    pictureDisplayMoreClear = "cn.pictureDisplayMoreClear",
    customColorClearView = "cn.customColorClearView",
    quickOneClickPayment = "cn.quickOneClickPayment",
    combinePyment = "cn.combinePyment",
    quickCombineDisplay = "cn.quickCombineDisplay",
    recommendationQuicAddPurchase = "cn.recommendationQuicAddPurchase",
    miniProgramOrderTool = "cn.miniProgramOrderTool",
    enhanceCustomerDiningExperience = "cn.enhanceCustomerDiningExperience",
    multiPersonOrderAcceleratesEfficiency = "cn.multiPersonOrderAcceleratesEfficiency",
    customerReduceCosts = "cn.customerReduceCosts",
    multiModesEnhancePerformance = "cn.multiModesEnhancePerformance",
    categoriesRecruitsDishes = "cn.categoriesRecruitsDishes",
    supportAdvanceOrder = "cn.supportAdvanceOrder",
    collaborativeQuicklyProcess = "cn.collaborativeQuicklyProcess",
    selfPickupOrder = "cn.selfPickupOrder",
    tableCodeOrder = "cn.tableCodeOrder",
    storeCodeOrderingTakeaway = "cn.storeCodeOrderingTakeaway",
    onePersonOneSizeOrder = "cn.onePersonOneSizeOrder",
    timeSlotIncreasesSales = "cn.timeSlotIncreasesSales",
    multiMembershipAattractCustomers = "cn.multiMembershipAattractCustomers",
    selfServiceOrdeUserExperience = "cn.selfServiceOrdeUserExperience",
    enhanceCustomerIncreaseEfficiency = "cn.enhanceCustomerIncreaseEfficiency",
    aggregateTakeaway = "cn.aggregateTakeaway",
    unifiedOrderImproveEfficiency = "cn.unifiedOrderImproveEfficiency",
    combineSoftwareServices = "cn.combineSoftwareServices",
    improveFriendlyDining = "cn.improveFriendlyDining",
    KDSProductWebsitManage = "cn.KDSProductWebsitManage",
    quicklyPrepareMakeDishes = "cn.quicklyPrepareMakeDishes",
    realTimeSyncGreaterConvenience = "cn.realTimeSyncGreaterConvenience",
    calledByChannel = "cn.calledByChannel",
    followOrderCoordinate = "cn.followOrderCoordinate",
    createOmnichannelUserGrowth = "cn.createOmnichannelUserGrowth",
    empowerGrowthUserValue = "cn.empowerGrowthUserValue",
    getGlobalUsers = "cn.getGlobalUsers",
    createExclusiveUserRights = "cn.createExclusiveUserRights",
    accurateInsightUserDemands = "cn.accurateInsightUserDemands",
    comprehensivePreciseReach = "cn.comprehensivePreciseReach",
    integrateUsersOutreach = "cn.integrateUsersOutreach",
    createUserRightsImproveLoyalty = "cn.createUserRightsImproveLoyalty",
    confirmUserProfilesRepeatPurchases = "cn.confirmUserProfilesRepeatPurchases",
    SCRMPreciseOutreach = "cn.SCRMPreciseOutreach",
    connectSupplyChainEnterprises = "cn.connectSupplyChainEnterprises",
    fullBusinessDisplaySupplyChain = "cn.fullBusinessDisplaySupplyChain",
    completeClosedLoopSupplyChain = "cn.completeClosedLoopSupplyChain",
    supportMobileOperation = "cn.supportMobileOperation",
    supportInventoryQuery = "cn.supportInventoryQuery",
    supportDailyWeeklyMonthlyDisks = "cn.supportDailyWeeklyMonthlyDisks",
    supportDailyViewDiff = "cn.supportDailyViewDiff",
    supportInventoryPrompts = "cn.supportInventoryPrompts",
    supportInterTransfer = "cn.supportInterTransfer",
    supportStoreRawMaterial = "cn.supportStoreRawMaterial",
    supportIntelligentOrder = "cn.supportIntelligentOrder",
    supportWarehouseAnalysis = "cn.supportWarehouseAnalysis",
    supportMultiSpecifyManage = "cn.supportMultiSpecifyManage",
    comprehensiveInsightAnalysis = "cn.comprehensiveInsightAnalysis",
    makeInformedDecisions = "cn.makeInformedDecisions",
    teaDrinksExclusiveCashierOrder = "cn.teaDrinksExclusiveCashierOrder",
    quickOrderReduceIncreasesEfficiency = "cn.quickOrderReduceIncreasesEfficiency",
    multiScreenDisplay = "cn.multiScreenDisplay",
    marketingOrdersNotWrong = "cn.marketingOrdersNotWrong",
    displayOrderStatusPickUpMeals = "cn.displayOrderStatusPickUpMeals",
    displayByChannel = "cn.displayByChannel",
    realTimeMenuPromoteConsumption = "cn.realTimeMenuPromoteConsumption",
    realtimeViewOrderInfo = "cn.realtimeViewOrderInfo",
    realTimDisplayAttractCustomers = "cn.realTimDisplayAttractCustomers",
    miniProgramHelpsLaborCosts = "cn.miniProgramHelpsLaborCosts",
    enhanceCustomerdinerExperienceEfficiency = "cn.enhanceCustomerdinerExperienceEfficiency",
    intelligentManageRestaurants = "cn.intelligentManageRestaurants",
    classicCase = "cn.classicCase",
    publicPrivateIntegratedOperation = "cn.publicPrivateIntegratedOperation",
    multipleOrderMethods = "cn.multipleOrderMethods",
    diffServicesEnhanceOperationalEfficiency = "cn.diffServicesEnhanceOperationalEfficiency",
    scanCodeOrderQuickly = "cn.scanCodeOrderQuickly",
    takesOrderServes = "cn.takesOrderServes",
    handheldOrderHumanizedService = "cn.handheldOrderHumanizedService",
    directTicketQuickProduct = "cn.directTicketQuickProduct",
    quickVerificationCombinePayment = "cn.quickVerificationCombinePayment",
    PADOrderMoreFlexible = "cn.PADOrderMoreFlexible",
    orderDirectlyKitchenPreparation = "cn.orderDirectlyKitchenPreparation",
    syncOrdersCheckoutServer = "cn.syncOrdersCheckoutServer",
    tableMode = "cn.tableMode",
    improvingRestaurantMoreClearly = "cn.improvingRestaurantMoreClearly",
    layoutRestaurant = "cn.layoutRestaurant",
    easySetup = "cn.easySetup",
    clearCostCalculation = "cn.clearCostCalculation",
    diversifiedOperations = "cn.diversifiedOperations",
    quickVerify = "cn.quickVerify",
    actualLayoutRestaurant = "cn.actualLayoutRestaurant",
    automaticallyAddTableware = "cn.automaticallyAddTableware",
    fixedNumberTableClearBill = "cn.fixedNumberTableClearBill",
    multiOperationsMoreFlexibly = "cn.multiOperationsMoreFlexibly",
    quicklyOrdersEfficiency = "cn.quicklyOrdersEfficiency",
    rersonalizedBusinessModel = "cn.rersonalizedBusinessModel",
    moreFlexibleManageOperation = "cn.moreFlexibleManageOperation",
    regionalServicesReflectDiff = "cn.regionalServicesReflectDiff",
    timeSlotOperationBusinessModels = "cn.timeSlotOperationBusinessModels",
    pricesFlavorsDiffRegions = "cn.pricesFlavorsDiffRegions",
    serviceFeesDiff = "cn.serviceFeesDiff",
    diffBusinessOperations = "cn.diffBusinessOperations",
    pricesTasteMethodDiff = "cn.pricesTasteMethodDiff",
    multiPortOrderSimpleComplexity = "cn.multiPortOrderSimpleComplexity",
    inventoryManage = "cn.inventoryManage",
    foodStallManage = "cn.foodStallManage",
    costManagement = "cn.costManagement",
    allocateMaterials = "cn.allocateMaterials",
    diffTypesOfFood = "cn.diffTypesOfFood",
    dataSupportProcessPorts = "cn.dataSupportProcessPorts",
    moreArchiveCapabilities = "cn.moreArchiveCapabilities",
    mealByCategory = "cn.mealByCategory",
    waimaiStall = "cn.waimaiStall",
    productByOrder = "cn.productByOrder",
    oneDishOneOrder = "cn.oneDishOneOrder",
    mulOrdersPerDish = "cn.mulOrdersPerDish",
    stayStage = "cn.stayStage",
    cutVegetables = "cn.cutVegetables",
    quickQRCodeCreation = "cn.quickQRCodeCreation",
    oneOrderOneVote = "cn.oneOrderOneVote",
    kitchenManageKDS = "cn.kitchenManageKDS",
    paperlessKitchenEasier = "cn.paperlessKitchenEasier",
    productManage = "cn.productManage",
    gapMonitoring = "cn.gapMonitoring",
    diffOutletsMergeproduct = "cn.diffOutletsMergeproduct",
    orderMealsSatisfyMeals = "cn.orderMealsSatisfyMeals",
    variousStagesReduceLoss = "cn.variousStagesReduceLoss",
    variousStatusesSeamlessDock = "cn.variousStatusesSeamlessDock",
    diffChannelClassifyClearly = "cn.diffChannelClassifyClearly",
    onlineAndOfflineBook = "cn.onlineAndOfflineBook",
    advanceBookBetterDining = "cn.advanceBookBetterDining",
    KDSWebsiteManage = "cn.KDSWebsiteManage",
    syncPaperlessKitchen = "cn.syncPaperlessKitchen",
    diffChannelsDiffNumbers = "cn.diffChannelsDiffNumbers",
    orderCoordinateWithKitchen = "cn.orderCoordinateWithKitchen",
    simpleProcessScanCodeQuicklyServe = "cn.simpleProcessScanCodeQuicklyServe",
    divertingDinersReducQueues = "cn.divertingDinersReducQueues",
    quickPaymentConnecKitchenQuickOrde = "cn.quickPaymentConnecKitchenQuickOrde",
    oneClassOneVote = "cn.oneClassOneVote",
    selfPickup = "cn.selfPickup",
    quickPayment = "cn.quickPayment",
    realTimeViewProgress = "cn.realTimeViewProgress",
    multiCodePayment = "cn.multiCodePayment",
    memberQuickAccess = "cn.memberQuickAccess",
}

export default IndustryEnum;