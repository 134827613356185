import ChainControlEnum from "@src/Locales/Constants/CN/ChainControlEnum";
import EfficientEnum from "@src/Locales/Constants/CN/EfficientEnum";
import FooterEnum from "@src/Locales/Constants/CN/FooterEnum"
import HeaderEnum from "@src/Locales/Constants/CN/HeaderEnum";
import HomeEnum from "@src/Locales/Constants/CN/HomeEnum";
import ManageDecisionEnum from "@src/Locales/Constants/CN/ManageDecisionEnum";
import MarketEnum from "@src/Locales/Constants/CN/MarketEnum";
import OnlineRestaurantEnum from "@src/Locales/Constants/CN/OnlineRestaurantEnum";
import SupplyChainEnum from "@src/Locales/Constants/CN/SupplyChainEnum";
import ContactModalEnum from "@src/Locales/Constants/CN/ContactModalEnum";
import DownloadEnum from "@src/Locales/Constants/CN/DownloadEnum";
import IndustryEnum from "@src/Locales/Constants/CN/IndustryEnum";
// 以 cn 为开头
export enum CommonCNEnum {
    LOADING = "cn.loading",
}

export const CNEnum = {
    ...CommonCNEnum,
    ...ChainControlEnum,
    ...EfficientEnum,
    ...FooterEnum,
    ...HeaderEnum,
    ...HomeEnum,
    ...ManageDecisionEnum,
    ...MarketEnum,
    ...OnlineRestaurantEnum,
    ...SupplyChainEnum,
    ...ContactModalEnum,
    ...DownloadEnum,
    ...IndustryEnum,
};
export default CNEnum;