enum GestionyControlEnum {
    gestionyControlBannerHeaderTitleItalicBeforeSpan = "gestionyControl.bannerHeaderTitleItalicBeforeSpan",
    gestionyControlTitleItalic = "gestionyControl.bannerHeaderTitleItalic",
    gestionyControlBannerHeaderDesc = "gestionyControl.bannerHeaderDesc",

    gestionyControlHalfContainer1LeftTitle1 = "gestionyControl.halfContainer1LeftTitle1",
    gestionyControlHalfContainer1LeftDesc1 = "gestionyControl.halfContainer1LeftDesc1",
    gestionyControlHalfContainer1LeftTitle2 = "gestionyControl.halfContainer1LeftTitle2",
    gestionyControlHalfContainer1LeftDesc2 = "gestionyControl.halfContainer1LeftDesc2",
    gestionyControlHalfContainer1LeftTitle3 = "gestionyControl.halfContainer1LeftTitle3",
    gestionyControlHalfContainer1LeftDesc3 = "gestionyControl.halfContainer1LeftDesc3",
    gestionyControlHalfContainer1LeftTitle4 = "gestionyControl.halfContainer1LeftTitle4",
    gestionyControlHalfContainer1LeftDesc4 = "gestionyControl.halfContainer1LeftDesc4",

    gestionyControlHalfContainer1RightFlowChartText1 = "gestionyControl.halfContainer1RightFlowChartText1",
    gestionyControlHalfContainer1RightFlowChartText2 = "gestionyControl.halfContainer1RightFlowChartText2",
    gestionyControlHalfContainer1RightFlowChartText3 = "gestionyControl.halfContainer1RightFlowChartText3",
    gestionyControlHalfContainer1RightFlowChartText4 = "gestionyControl.halfContainer1RightFlowChartText4",
    gestionyControlHalfContainer1RightFlowChartText5 = "gestionyControl.halfContainer1RightFlowChartText5",
    gestionyControlHalfContainer1RightFlowChartText6 = "gestionyControl.halfContainer1RightFlowChartText6",
    gestionyControlHalfContainer1RightFlowChartText7 = "gestionyControl.halfContainer1RightFlowChartText7",
    gestionyControlHalfContainer1RightFlowChartText8 = "gestionyControl.halfContainer1RightFlowChartText8",
    gestionyControlHalfContainer1RightFlowChartText9 = "gestionyControl.halfContainer1RightFlowChartText9",
    gestionyControlHalfContainer1RightFlowChartText10 = "gestionyControl.halfContainerRightFlowChartText10",
    gestionyControlHalfContainer1RightFlowChartText11 = "gestionyControl.halfContainerRightFlowChartText11",


    gestionyControlHalfContainer2LeftFlowChartText1 = "gestionyControl.halfContainer2LeftFlowChartText1",
    gestionyControlHalfContainer2LeftFlowChartDesc1 = "gestionyControl.halfContainer2LeftFlowChartDesc1",
    gestionyControlHalfContainer2LeftFlowChartText2 = "gestionyControl.halfContainer2LeftFlowChartText2",
    gestionyControlHalfContainer2LeftFlowChartDesc2 = "gestionyControl.halfContainer2LeftFlowChartDesc2",
    gestionyControlHalfContainer2LeftFlowChartText3 = "gestionyControl.halfContainer2LeftFlowChartText3",
    gestionyControlHalfContainer2LeftFlowChartDesc3 = "gestionyControl.halfContainer2LeftFlowChartDesc3",

    gestionyControlHalfContainer2RightTitle = "gestionyControl.halfContainer2RightTitle",
    gestionyControlHalfContainer2RightDesc1 = "gestionyControl.halfContainer2RightDesc1",
    gestionyControlHalfContainer2RightDesc2 = "gestionyControl.halfContainer2RightDesc2",

    gestionyControlHalfContainer3Title = "gestionyControl.halfContainer3Title",
    gestionyControlHalfContainer3Desc1 = "gestionyControl.halfContainer3Desc1",
    gestionyControlHalfContainer3Desc2 = "gestionyControl.halfContainer3Desc2",
    gestionyControlHalfContainer3Desc3 = "gestionyControl.halfContainer3Desc3",

    gestionyControlAllContainerTitle1 = "gestionyControl.allContainerTitle1",
    gestionyControlAllContainerTitle2 = "gestionyControl.allContainerTitle2",
    gestionyControlAllContainerDesc1 = "gestionyControl.allContainerDesc1",
    gestionyControlAllContainerDesc2 = "gestionyControl.allContainerDesc2",
    gestionyControlAllContainerDesc3 = "gestionyControl.allContainerDesc3",

    gestionyControlAllContainerLogo1Title1 = "gestionyControl.allContainerLogo1Title1",
    gestionyControlAllContainerLogo2Title2 = "gestionyControl.allContainerLogo2Title2",
    gestionyControlAllContainerLogo3Title3 = "gestionyControl.allContainerLogo3Title3",
    gestionyControlAllContainerLogo4Title4 = "gestionyControl.allContainerLogo4Title4",

    gestionyControlAllContainerFlowChartTitle1 = "gestionyControl.allContainerFlowChartTitle1",
    gestionyControlAllContainerFlowChartTitle2 = "gestionyControl.allContainerFlowChartTitle2",
    gestionyControlAllContainerFlowChartTitle3 = "gestionyControl.allContainerFlowChartTitle3",
    gestionyControlAllContainerFlowChartGroup = "gestionyControl.allContainerFlowChartGroup",
    gestionyControlAllContainerFlowChartGroupTitle = "gestionyControl.allContainerFlowChartGroupTitle",
    gestionyControlAllContainerFlowChartUsuario = "gestionyControl.allContainerFlowChartUsuario",
    gestionyControlAllContainerFlowChartOrg = "gestionyControl.allContainerFlowChartOrga",
    gestionyControlAllContainerFlowChartTextTol = "gestionyControl.allContainerFlowChartTextTol",

    gestionyControlAllContainerFlowChartText1 = "gestionyControl.allContainerFlowChartText1",
    gestionyControlAllContainerFlowChartText2 = "gestionyControl.allContainerFlowChartText2",
    gestionyControlAllContainerFlowChartText3 = "gestionyControl.allContainerFlowChartText3",
    gestionyControlAllContainerFlowChartText4 = "gestionyControl.allContainerFlowChartText4",
    gestionyControlAllContainerFlowChartText5 = "gestionyControl.allContainerFlowChartText5",
    gestionyControlAllContainerFlowChartText6 = "gestionyControl.allContainerFlowChartText6",
    gestionyControlAllContainerFlowChartText7 = "gestionyControl.allContainerFlowChartText7",
    gestionyControlAllContainerFlowChartText8 = "gestionyControl.allContainerFlowChartText8",
    gestionyControlAllContainerFlowChartText9 = "gestionyControl.allContainerFlowChartText9",
    gestionyControlAllContainerFlowChartText10 = "gestionyControl.allContainerFlowChartText10",

    // 废弃
    // gestionyControlBackGroundTitle = "gestionyControl.backGroundTitle",
    // gestionyControlBackGroundDesc = "gestionyControl.backGroundDesc",
    // 免费试用 international.contactButton  => contactButton



}

export default GestionyControlEnum