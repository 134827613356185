enum ElectricMenuEnum {
    electricMenuTitleItalic = "electricMenu.bannerHeaderTitleItalic",
    electricMenuTitleItalicAfterSpan = "electricMenu.bannerHeaderTitleItalicAfterSpan",
    electricMenuBannerHeaderDesc1 = "electricMenu.bannerHeaderDesc1",

    electricMenuHalfContainerImgUnionText1Title = "electricMenu.halfContainerImgUnionText1Title",
    electricMenuHalfContainerImgUnionText1Desc1 = "electricMenu.halfContainerImgUnionText1Desc1",

    electricMenuHalfContainerImgUnionText2Title = "electricMenu.halfContainerImgUnionText2Title",
    electricMenuHalfContainerImgUnionText2Desc1 = "electricMenu.halfContainerImgUnionText2Desc1",
    electricMenuHalfContainerImgUnionText2Desc2 = "electricMenu.halfContainerImgUnionText2Desc2",
}

export default ElectricMenuEnum