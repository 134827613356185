import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as CheckFilled } from "@src/assets/CN/svg/check-filled.svg";
import { ReactComponent as CheckEmpty } from "@src/assets/CN/svg/check-empty.svg";
import { ReactComponent as BlackCall } from "@src/assets/CN/svg/black-call.svg";
import { ReactComponent as CaretDown } from "@src/assets/CN/svg/caret-down-small.svg";
import AddressLinkage from "./AddressLinkage";
import { addUserContactCN } from "@src/Api/CN";
import "./index.less";
import { useTranslation } from "react-i18next";
import CNEnum from "@src/Locales/Constants/CNEnum";
import i18n from "@src/Basic/Language/i18n";

export default function (props: any) {
  const { isOpen, handleIsOpen } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chainStatus, setChainStatus] = useState<string>("CHAIN");
  const { t } = useTranslation();

  const chainOptions = useMemo(() => {
    return [
      {
        label: t(CNEnum.yesLabel),
        value: "CHAIN",
      },
      {
        label: t(CNEnum.noLabel),
        value: "SINGLE",
      },
    ];
  }, [i18n.language]);

  const businessTypeOptions = useMemo(() => {
    return [
      {
        label: t(CNEnum.fastFood),
        value: "2",
      },
      {
        label: t(CNEnum.teaDrink),
        value: "3",
      },
      {
        label: t(CNEnum.dinner),
        value: "1",
      },
      {
        label: t(CNEnum.bar),
        value: "4",
      },
      {
        label: t(CNEnum.comprehensive),
        value: "5",
      },
      {
        label: t(CNEnum.cafeLabel),
        value: "4",
      },
      {
        label: t(CNEnum.bakingShop),
        value: "5",
      },
    ];
  }, [i18n.language]);
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const { contactAddress = {}, ...values } = await form.validateFields();
      addUserContactCN({ ...values, ...contactAddress }).then((res) => {
        message.success(t(CNEnum.submitSuccess), 1.5);
        handleIsOpen(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = () => {
    handleIsOpen(false);
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    form.setFieldValue("chainStatus", chainStatus);
  }, [chainStatus]);

  return (
    <Modal className="PCConcatModal" width={640} open={isModalOpen} closeIcon={null} footer={null}>
      <h2 className="title">{t(CNEnum.leaveContactInfo)}</h2>
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label={t(CNEnum.corporateName)} name="companyName">
              <Input placeholder={t(CNEnum.pleaseInput)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t(CNEnum.contactPerson)} name="firstName">
              <Input placeholder={t(CNEnum.pleaseInput)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t(CNEnum.contactPhone)}
              name="phoneNumber"
              rules={[{ required: true, message: t(CNEnum.pleaseInputPhone) }]}
            >
              <Input prefix={<BlackCall />} placeholder={t(CNEnum.pleaseInput)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t(CNEnum.contactMailbox)} name="email">
              <Input placeholder={t(CNEnum.pleaseInput)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label={t(CNEnum.contactAddress)} name="contactAddress">
              <AddressLinkage />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t(CNEnum.streetName)} name="street">
              <Input placeholder={t(CNEnum.pleaseInput)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label={t(CNEnum.houseNumber)} name="addressNumber">
              <Input placeholder={t(CNEnum.pleaseInput)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t(CNEnum.postalCode)} name="postalCode">
              <Input placeholder={t(CNEnum.pleaseInput)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label={t(CNEnum.businessFormat)} name="businessType">
              <Select
                placeholder={t(CNEnum.pleaseSelect)}
                style={{ width: "100%" }}
                suffixIcon={<CaretDown />}
                options={businessTypeOptions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t(CNEnum.isChain)} name="chainStatus">
              <div className="w-[130PX] flex justify-between">
                {chainOptions.map((item: any) => {
                  return (
                    <span
                      className="radioOption"
                      key={item.value}
                      onClick={() => {
                        setChainStatus(item.value);
                      }}
                    >
                      {chainStatus === item.value ? <CheckFilled /> : <CheckEmpty />}
                      <span className="ml-[10PX]">{item.label}</span>
                    </span>
                  );
                })}
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="btnWrap">
        <div className="btn cursor-pointer" onClick={handleCancel}>
          {t(CNEnum.close)}
        </div>
        <div className="btn submit cursor-pointer" onClick={handleSubmit}>
          {t(CNEnum.submit)}
        </div>
      </div>
    </Modal>
  );
}
