import LocalForageBaseStore from "./LocalForageBaseStore";

class LanguageLocalForageStore extends LocalForageBaseStore {
  static instance: LanguageLocalForageStore | null = null;

  constructor() {
    super({
      storeName: "languageData",
      version: 1,
      description: "set language data",
    });
  }
  // Singleton
  static getInstance() {
    if (!this.instance) {
      this.instance = new LanguageLocalForageStore();
    }
    return this.instance;
  }

  async get(key: string) {
    const _itemValue = await super.get(key);
    return _itemValue;
  }
}
export default LanguageLocalForageStore.getInstance();
