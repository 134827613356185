enum FooterEnum {
    experience = "cn.experience",
    businessType = "cn.businessType",
    fastFood = "cn.fastFood",
    dinner = "cn.dinner",
    teaDrink = "cn.teaDrink",
    hotPot = "cn.hotPot",
    weigh = "cn.weigh",
    baking = "cn.baking",
    bar = "cn.bar",
    coffee = "cn.coffee",
    professionalServices = "cn.professionalServices",
    resource = "cn.resource",
    quotation = "cn.quotation",
    selectReason = "cn.selectReason",
    support = "cn.support",
    developerPlatform = "cn.developerPlatform",
    community = "cn.community",
    contact = "cn.contact",
    customerSupport = "cn.customerSupport",
    sale = "cn.sale",
    branchOffice = "cn.branchOffice",
    cooperativePartner = "cn.cooperativePartner",
    serviceAgreement = "cn.serviceAgreement",
    privacyPolicy = "cn.privacyPolicy",
    taoquxianCommpany = "cn.taoquxianCommpany",
    mailbox = "cn.mailbox",
    downloadCenter = "cn.downloadCenter",
}
export default FooterEnum;