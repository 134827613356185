enum DeliveryUnionTakeAwayEnum {
    deliveryUnionTakeAwayBannerHeaderTitleItalicBeforeSpan = "deliveryUnionTakeAway.bannerHeaderTitleItalicBeforeSpan",
    deliveryUnionTakeAwayTitleItalic = "deliveryUnionTakeAway.bannerHeaderTitleItalic",
    deliveryUnionTakeAwayBannerHeaderTitleItalicAfterSpan = "deliveryUnionTakeAway.bannerHeaderTitleItalicAfterSpan",
    deliveryUnionTakeAwayBannerHeaderDesc1 = "deliveryUnionTakeAway.bannerHeaderDesc1",
    deliveryUnionTakeAwayBannerHeaderDesc2 = "deliveryUnionTakeAway.bannerHeaderDesc2",

    deliveryUnionTakeAwayHalfContainerText1Title = "deliveryUnionTakeAway.halfContainerText1Title",
    deliveryUnionTakeAwayHalfContainerText1Desc = "deliveryUnionTakeAway.halfContainerText1Desc",


    deliveryUnionTakeAwayPicText1Title1 = "deliveryUnionTakeAway.picText1Title1",
    deliveryUnionTakeAwayPicText1Title2 = "deliveryUnionTakeAway.picText1Title2",
    deliveryUnionTakeAwayPicText1Title3 = "deliveryUnionTakeAway.picText1Title3",


    deliveryUnionTakeAwayHalfContainerText2Title = "deliveryUnionTakeAway.halfContainerText2Title",

    deliveryUnionTakeAwayBackGroundUrlTitle = "deliveryUnionTakeAway.backGroundUrlTitle",
    deliveryUnionTakeAwayBackGroundUrlDesc = "deliveryUnionTakeAway.backGroundUrlDesc",


}

export default DeliveryUnionTakeAwayEnum