import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as Logo } from "@src/assets/CN/svg/logo.svg";
import { ReactComponent as International } from "@src/assets/CN/svg/international.svg";
import { ReactComponent as Call } from "@src/assets/CN/svg/call.svg";
import NavList from "./NavList";
import ContactModal from "@src/Components/CNComponents/ContactModal";
import { useTranslation } from "react-i18next";
import CNEnum from "@src/Locales/Constants/CNEnum";
import GlobalConfig from "@src/GlobalConfig";
import "./index.less";
import SwitchLanguage from "./SwitchLanguage";
import ContactContainer from "@src/Components/CNComponents/ContactContainer";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "@src/Basic/Language/i18n";

const PCHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const Contact = useMemo(() => {
    return (
      <div className="flex items-center ml-[32PX]">
        <ContactContainer>
          <div className="contact flex items-center">
            <Call />
            <span className="text-white ml-[2px]">{t(CNEnum.contactUs)}</span>
          </div>
        </ContactContainer>
      </div>
    );
  }, [i18n.language]);

  return (
    <div className="headerWrap">
      <header className="header m-auto flex items-center justify-between">
        <div
          className="logo cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        ></div>
        <NavList />
        <div className="flex justify-around h-full">
          <SwitchLanguage />
          {Contact}
        </div>
      </header>
    </div>
  );
};
export default PCHeader;
