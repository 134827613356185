import { ReactNode, useState } from "react";
import ContactModal from "../ContactModal";

type IProps = {
  children: ReactNode;
};
const ContactContainer = (props: IProps) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleIsOpen = (flag: boolean) => {
    setIsOpen(flag);
  };

  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {children}
      </div>
      <ContactModal isOpen={isOpen} handleIsOpen={handleIsOpen} />
    </>
  );
};

export default ContactContainer;
