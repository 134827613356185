import { Select, ConfigProvider, Empty } from "antd";
import { ReactComponent as CaretDown } from "@src/assets/CN/svg/caret-down-small.svg";
import listAreaQueryCN from "@src/consts/listAreaQueryCN.json";
import "./index.less";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import NoData from "@src/Basic/NotData";
import CNEnum from "@src/Locales/Constants/CNEnum";
export default function AddressLinkage(props: any) {
  const { onChange } = props;
  const [values, setValues] = useState<any>({});
  const [cityData, setCityData] = useState<any[]>([]);
  const [districtData, setDistrictData] = useState<any[]>([]);
  const { t } = useTranslation();
  const areaData = useMemo(() => {
    return listAreaQueryCN.data.list;
  }, [listAreaQueryCN]);

  const provinceData = useMemo(() => {
    return areaData.filter((item) => {
      return item.administrationLevel === "1";
    });
  }, [areaData]);

  const selectList = useMemo(() => {
    return [
      { title: t(CNEnum.province), key: "province", dataSource: provinceData },
      {
        title: t(CNEnum.municipality),
        key: "city",
        dataSource: cityData,
        emptyTip: t(CNEnum.pleaseSelectProvince),
      },
      {
        title: t(CNEnum.area),
        key: "district",
        dataSource: districtData,
        emptyTip: t(CNEnum.pleaseSelectCity),
      },
    ];
  }, [provinceData, cityData, districtData, values]);

  const handleSelect = (key: string, value: string) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  useEffect(() => {
    const data = areaData.filter((item) => item.parentAreaCode === values.province);
    setCityData(data);
    setDistrictData([]);
    setValues({
      ...values,
      city: undefined,
      district: undefined,
    });
  }, [values.province]);

  useEffect(() => {
    const data = areaData.filter((item) => item.parentAreaCode === values.city);
    setDistrictData(data);
    setValues({
      ...values,
      district: undefined,
    });
  }, [values.city]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div className="addressLinkage flex">
      {selectList.map((item: any) => {
        return (
          <ConfigProvider renderEmpty={() => <NoData desc={item.emptyTip} />}>
            <Select
              className="flex-none"
              suffixIcon={<CaretDown />}
              style={{ width: 100 }}
              allowClear
              dropdownStyle={{
                width: "284px",
              }}
              fieldNames={{
                label: "text",
                value: "areaCode",
              }}
              value={values[item.key]}
              onChange={(value) => {
                handleSelect(item.key, value);
              }}
              options={item.dataSource}
              placeholder={item.title}
            />
          </ConfigProvider>
        );
      })}
    </div>
  );
}
