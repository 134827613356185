export enum CommonEnum {
  TestText = 'common.test',
  globalServiceCentre = "co.globalServiceCentre",
  globalServiceSupport = "co.globalServiceSupport",
  provideNationalSupport = "co.provideNationalSupport",
  CoChina = "co.china",
  CoCanada = "co.canada",
  CoUs = "co.us",
  CoEurope = "co.europe",
  CoAustralia = "co.australia",
  CoSingapore = "co.singapore",
  CoCompanyName = "co.companyName",
  COintroduction = "co.introduction",
  COAddress = "co.address",
  CoContactPerson = "co.contactPerson",
  CoontactPhone = "co.contactPhone",
  CoEmail = "co.email",
  CoUen = "co.uen",
  canadaAddress = "co.canadaAddress",
  usAddress = "co.usAddress",
  europeAddress = "co.europeAddress",
  australiaAddress = "co.australiaAddress",
  singaporeAddress = "co.singaporeAddress",
  usIntroduce = "co.usIntroduce",
  europeIntroduce = "co.europeIntroduce",
  canadaCompany = "co.canadaCompany",
  usCompany = "co.usCompany",
  europeCompany = "co.europeCompany",
  australiaCompany = "co.australiaCompany",
  seaCompany = "co.seaCompany",
  coWebsite = "co.website",
  shanghaiAddress = "co.shanghaiAddress",
  beijingAddress = "co.beijingAddress",
  xianAddress = "co.xianAddress",
  suzhouAddress = "co.suzhouAddress",
  hangzhouAddress = "co.hangzhouAddress",
  chongqingAddress = "co.chongqingAddress",
  guangzhouAddress = "co.guangzhouAddress",
  wenqian = "co.wenqian",
  jinjin = "co.jinjin",
  zhangyuan = "co.zhangyuan",
  tingting = "co.tingting",
  huiting = "co.huiting",
  yipeng = "co.yipeng",
  linmin = "co.linmin",
  daisyZhang = "co.daisyZhang",
  herman = "co.herman",

  canadaIntroduceFirst = "co.canadaIntroduceFirst",
  canadaIntroduceSecond = "co.canadaIntroduceSecond",
  singaporeAddressFirst = "co.singaporeAddressFirst",
  singaporeAddressSecond = "co.singaporeAddressSecond",
  chinaCompany = "co.chinaCompany",


  coDate = "co.date", // 时间
  shanghaiCompanyName = "co.shanghaiCompanyName", // 上海睿食拓科技有限公司
  shanghaiTime = "co.shanghaiTime",  // 09:00-18:00  週一到週五
  shanghaiCity = "co.shanghaiCity",// 上海
  beijingCity = "co.beijing",
  xianCity = "co.xian",
  nanjingCity = "co.nanjing",
  hangzhouCity = "co.hangzhou",
  chongqingCity = "co.chongqing",
  guangzhouCity = "co.guangzhou",
  CoEs = "co.es",// 西班牙
  esCompany = "co.esCompany", // Ubimel Miteco SL
  esAddress = "co.esAddress", // Pg. de Gràcia, 87,Piso 6 L’Eixample, 08008 Barcelona , España
  esIntroduce = "co.esIntroduce",// Ubimel Miteco是Tizona Miteco的子公司，专注于数字化餐饮SaaS解决方案RestoSuite，帮助欧洲传统餐饮业进行线上线下一体化管理，涵盖下单、订单管理、支付、品牌营销、数据分析等核心业务，为日常经营和多品牌、多层级连锁管理提供便捷支持。该系统已在多个欧洲国家上线运营，助力餐饮企业实现数字化转型。
  CoItaly = "co.italy",// 意大利
  italyCompanyName = "co.italyCompanyName", // Ubimel Miteco SL
  italySataMariaSettembre = "co.italySataMariaSettembre", // Sara Maria.Settembre
  italyIntroduceFirst = "co.italyIntroduceFirst", // 意大利全区销售服务支持,中英意语言服务
  italyIntroduceSecond = "co.italyIntroduceSecond",// Ubimel Miteco是Tizona Miteco的子公司，专注于数字化餐饮SaaS解决方案RestoSuite，帮助欧洲传统餐饮业进行线上线下一体化管理，涵盖下单、订单管理、支付、品牌营销、数据分析等核心业务，为日常经营和多品牌、多层级连锁管理提供便捷支持。该系统已在多个欧洲国家上线运营，助力餐饮企业实现数字化转型。
  CoEngland = "co.england", // 英国
  englandCompanyName = "co.englangCompanyName", // Ubimel Miteco Ltd
  englandChangLiu = "co.englandChangLiu", // Chang liu
  englandIntroduceFirst = "co.englandIntroduceFirst", // 英国全区销售服务支持,中英双语言服务
  englandIntroduceSecond = "co.englandIntroduceSecond", // Ubimel Miteco是Tizona Miteco的子公司，专注于数字化餐饮SaaS解决方案RestoSuite，帮助欧洲传统餐饮业进行线上线下一体化管理，涵盖下单、订单管理、支付、品牌营销、数据分析等核心业务，为日常经营和多品牌、多层级连锁管理提供便捷支持。该系统已在多个欧洲国家上线运营，助力餐饮企业实现数字化转型。



  companyIntroFirst = "cn.companyIntroFirst",
  companyIntroSecond = "cn.companyIntroSecond",
  companyIntroduction = "cn.companyIntroduction",
  complianceSupportCountry = "cn.complianceSupportCountry",
  mission = "cn.mission",
  integrityEnterprisingInnovationAndWin = "cn.integrityEnterprisingInnovationAndWin",
  supportFoodDelivery = "cn.supportFoodDelivery",
  supportPayment = "cn.supportPayment",
  globalBusinessLayout = "cn.globalBusinessLayout",
  complianceSupport = "cn.complianceSupport",
  vision = "cn.vision",
  supportPaymentCountries = "cn.supportPaymentCountries",
  InternationalSupport = "cn.InternationalSupport",
  supportDeliveryCountries = "cn.supportDeliveryCountries",
  valueSystem = "cn.valueSystem",
  becomGlobalCateringIndustry = "cn.becomGlobalCateringIndustry",
  technologyDigitalUpgrading = "cn.technologyDigitalUpgrading",
  AboutUS = "common.aboutUS",
  companyIntroTitle = "common.companyIntroTitle",

  // 下载中心相关
  largeScreenOrder = "co.largeScreenOrder", // KIOSK
  mealOrder = "co.mealOrder",   // 点菜宝
  callScreen = "co.callScreen",
  numberSpeak = "co.numberSpeak",

  recommendConfig = "co.recommendConfig",
  minConfiguration = "co.minConfiguration",
  system = "co.system",
  memory = "co.memory",
  storage = "co.storage",
  electronicMenuTab = "co.electronicMenuTab",
  remoteDesktop = "common.remoteDesktop",

  posWinRecommendConfigCpu = "co.posWinRecommendConfigCpu",
  posWinRecommendConfigMemory = "co.posWinRecommendConfigMemory",
  posWinRecommendConfigStorage = "co.posWinRecommendConfigStorage",

  posAndroidRecommendConfigCpu = "co.posAndroidRecommendConfigCpu",
  posAndroidRecommendConfigMemory = "co.posAndroidRecommendConfigMemory",
  restoSuiteInventory = "co.restoSuiteInventory",
  restoSuiteSupplier = "co.restoSuiteSupplier",
  andAbove = "co.andAbove"

}
